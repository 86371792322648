import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary';
import LoadingSpinner from '~/components/LoadingSpinner/LoadingSpinner';
import theme from '~/theme/Theme';
import { NOT_FOUND_404 } from '~/constants/internalLinks';
import {
  publicRoutes,
  privateRoutes,
  adminRoutes,
  statsRoutes
} from './routeList';
import Header from '~/components/Header';
import AuthVerify from '~/containers/auth/AuthVerify';
import NotFound from '~/containers/notFound';
import RolesRoute from './Authorization/RolesRoute';

function Routes() {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            {publicRoutes.map((route) => (
              <Route key={route.path} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <>
              <Header />
              <Switch>
                {privateRoutes.map((route) => (
                  <RolesRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    roles={route.roles}>
                    <route.component />
                  </RolesRoute>
                ))}
                {adminRoutes.map((route) => (
                  <RolesRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    roles={route.roles}>
                    <route.component />
                  </RolesRoute>
                ))}
                {statsRoutes.map((route) => (
                  <RolesRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    roles={route.roles}>
                    <route.component />
                  </RolesRoute>
                ))}
                <Route path={NOT_FOUND_404} component={NotFound} />
                <Redirect to={NOT_FOUND_404} />
              </Switch>
            </>
            <Redirect to={NOT_FOUND_404} />
          </Switch>
          <AuthVerify />
        </Suspense>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default Routes;
