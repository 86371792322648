import {
  ReminderKeys,
  ReminderTarget,
  ReminderType
} from '~/services/api.types';
import { strings } from './strings';

export const reminderTypes = [
  { value: ReminderKeys.InterventionQuote, name: strings.Devis },
  {
    value: ReminderKeys.InterventionVisitReport,
    name: strings.Rapport_de_visite
  },
  {
    value: ReminderKeys.InterventionReceptionRecord,
    name: strings.PV_réception
  },
  {
    value: ReminderKeys.InterventionPreventionPlanFile,
    name: strings.Plan_prévention
  },
  {
    value: ReminderKeys.InterventionSav,
    name: strings.SAV
  },
  {
    value: ReminderKeys.InterventionInvoice,
    name: strings.Facture
  },
  {
    value: ReminderKeys.InterventionDeposit,
    name: strings.Acompte
  }
];

export const reminderMethods = [
  { value: ReminderType.Phone, name: strings.Telephone },
  {
    value: ReminderType.Mail,
    name: strings.Email
  }
];

export const reminderTarget = [
  { value: ReminderTarget.Agency, name: strings.Agence },
  {
    value: ReminderTarget.Customer,
    name: strings.Client
  }
];
