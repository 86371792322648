/* eslint-disable no-param-reassign */
export function isEmptyObject(value: any): value is object {
  if (value) {
    return Object.keys(value).length === 0 && value.constructor === Object;
  }
  return false;
}

export function isObjectAndNotEmpty(
  value: any
): value is { [key: string]: any } {
  if (value) {
    return Object.keys(value).length > 0 && value.constructor === Object;
  }
  return false;
}

export const cleanEmpty = (
  val: any,
  defaults = [undefined, null, NaN, '']
): any => {
  if (!defaults.length) return val;
  if (defaults.includes(val)) return {};

  if (Array.isArray(val))
    return val
      .map((v) => (v && typeof v === 'object' ? cleanEmpty(v, defaults) : v))
      .filter((v) => !defaults.includes(v));

  return Object.entries(val).length
    ? Object.entries(val)
        .map(([k, v]) => [
          k,
          v && typeof v === 'object' ? cleanEmpty(v, defaults) : v
        ])
        .reduce(
          (a, [k, v]) => (defaults.includes(v) ? a : { ...a, [k]: v }),
          {}
        )
    : val;
};

export function renameKeys(
  obj: object,
  newKeys: { [key: string]: string }
): object {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    // @ts-ignore
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function isEqualObject(obj1: any, obj2: any) {
  if (
    obj1 &&
    obj1.constructor === Object &&
    obj2 &&
    obj2.constructor === Object
  ) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
  return false;
}

export function createObjectIfDefined(
  key: string,
  value: any
): { [key: string]: any } | {} {
  return value ? { [key]: value } : {};
}

export function createBooleanObject(key: string, value: string | null) {
  return value
    ? {
        [key]: value === 'true'
      }
    : {};
}

export function cleanObject(obj: Record<string, any>): Record<string, any> {
  const cleanedObj: Record<string, any> = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
}
