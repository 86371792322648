import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CUSTOMER_RECORD,
  INTERVENTIONS,
  AGENCIES_ADMINISTRATION,
  BUILDING,
  ALL_INTERVENTIONS,
  VISIT_REPORT,
  BILLING,
  QUOTES_MANAGER,
  INVOICES_MANAGER,
  ALL_TODOS,
  ADMINISTRATION_AGENTS,
  MY_ACCOUNT,
  STATS_RECAP,
  STATS_ORDER,
  STATS_TURNOVER,
  STATS_CUSTOMERS_AND_AGENCIES,
  STATS_QUOTES,
  ADMINISTRATION_DEACTIVATED_CUSTOMERS,
  STATS_GC_AGENTS,
  TWIMM
} from '~/constants/internalLinks';
import { strings } from '~/constants/strings';

const DocumentTitle = () => {
  const location = useLocation();
  const locaSplit = location.pathname.split('/').slice(1, 4);
  const pathName = locaSplit?.pop();

  let pathNameTrad: string;
  switch (pathName) {
    case CUSTOMER_RECORD.split('/')[1]:
      pathNameTrad = strings.fiche_client;
      break;
    case INTERVENTIONS.split('/')[1]:
      pathNameTrad = strings.Lignes_d_intervention;
      break;
    case AGENCIES_ADMINISTRATION.split('/')[1]:
      pathNameTrad = strings.administration_des_agences_attila;
      break;
    case BUILDING.split('/')[1]:
      pathNameTrad = strings.historique_du_batiment;
      break;
    case ALL_INTERVENTIONS.split('/')[1]:
      pathNameTrad = strings.Toutes_les_interventions;
      break;
    case ALL_TODOS.split('/')[1]:
      pathNameTrad = strings.Tous_les_actions_non_terminées;
      break;
    case ADMINISTRATION_AGENTS.split('/')[2]:
    case ADMINISTRATION_DEACTIVATED_CUSTOMERS.split('/')[2]:
      pathNameTrad = strings.Administration;
      break;
    case TWIMM.split('/')[2]:
      pathNameTrad = strings.Administration;
      break;
    case VISIT_REPORT.split('/')[1]:
      pathNameTrad = strings.rapport_de_visite;
      break;
    case BILLING.split('/')[1]:
      pathNameTrad = strings.facturation;
      break;
    case QUOTES_MANAGER.split('/')[1]:
      pathNameTrad = strings.Gestionnaire_de_devis;
      break;
    case INVOICES_MANAGER.split('/')[1]:
      pathNameTrad = strings.Gestionnaire_de_factures;
      break;
    case MY_ACCOUNT.split('/')[1]:
      pathNameTrad = strings.Mon_compte;
      break;
    case STATS_RECAP.split('/')[2]:
    case STATS_ORDER.split('/')[2]:
    case STATS_QUOTES.split('/')[2]:
    case STATS_TURNOVER.split('/')[2]:
    case STATS_GC_AGENTS.split('/')[2]:
    case STATS_CUSTOMERS_AND_AGENCIES.split('/')[2]:
      pathNameTrad = strings.Statistique;
      break;
    default:
      pathNameTrad = '';
  }

  useEffect(() => {
    if (!pathName) {
      document.title = 'ATTILA GC';
    } else {
      document.title = `ATTILA GC - ${pathNameTrad}`;
    }
  }, [pathName, pathNameTrad]);

  return null;
};

export default DocumentTitle;
