import { lazy } from 'react';
import {
  CUSTOMER_RECORD,
  HOME,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  BUILDING,
  AGENCIES_ADMINISTRATION,
  INTERVENTIONS,
  ALL_INTERVENTIONS,
  VISIT_REPORT,
  BILLING,
  QUOTES_MANAGER,
  INVOICES_MANAGER,
  ALL_TODOS,
  ADMINISTRATION,
  MY_ACCOUNT,
  STATS,
  ACCESS_DENIED
} from '~/constants/internalLinks';
import { Roles } from '~/redux/features/user/userAuthSlice';

export const publicRoutes = [
  {
    path: LOGIN,
    exact: true,
    component: lazy(() => import('~/containers/auth/Login')),
    roles: []
  },
  {
    path: FORGOT_PASSWORD,
    exact: true,
    component: lazy(() => import('~/containers/auth/ForgotPassword')),
    roles: []
  },
  {
    path: RESET_PASSWORD,
    exact: true,
    component: lazy(() => import('~/containers/auth/ResetPassword')),
    roles: []
  },
  {
    path: LOGOUT,
    exact: true,
    component: lazy(() => import('~/containers/auth/Logout')),
    roles: []
  }
];

export const privateRoutes = [
  {
    path: HOME,
    exact: true,
    component: lazy(() => import('~/containers/home')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: CUSTOMER_RECORD,
    exact: true,
    component: lazy(() => import('~/containers/customerRecord/CustomerRecord')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: BUILDING,
    exact: true,
    component: lazy(() => import('~/containers/building/Building')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: AGENCIES_ADMINISTRATION,
    exact: true,
    component: lazy(
      () => import('~/containers/agenciesAdministration/AgenciesAdministration')
    ),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: INTERVENTIONS,
    exact: true,
    component: lazy(() => import('~/containers/interventions/Interventions')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: ALL_INTERVENTIONS,
    exact: true,
    component: lazy(
      () => import('~/containers/interventions/AllInterventions')
    ),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: ALL_TODOS,
    exact: true,
    component: lazy(() => import('~/containers/allTodos')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: VISIT_REPORT,
    exact: true,
    component: lazy(() => import('~/containers/visitReport/VisitReport')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: BILLING,
    exact: true,
    component: lazy(() => import('~/containers/billing/Billing')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: QUOTES_MANAGER,
    exact: true,
    component: lazy(() => import('~/containers/manager/Manager')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: INVOICES_MANAGER,
    exact: true,
    component: lazy(() => import('~/containers/manager/Manager')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: MY_ACCOUNT,
    exact: true,
    component: lazy(() => import('~/containers/myAccount')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  },
  {
    path: ACCESS_DENIED,
    exact: true,
    component: lazy(() => import('~/containers/accessDenied')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  }
];

export const adminRoutes = [
  {
    path: ADMINISTRATION,
    exact: false,
    component: lazy(() => import('~/routes/SubRoutes/AdministrationRoute')),
    roles: [Roles.Admin]
  }
];

export const statsRoutes = [
  {
    path: STATS,
    exact: false,
    component: lazy(() => import('~/routes/SubRoutes/StatsRoute')),
    roles: [Roles.Admin, Roles.Accountant, Roles.User, Roles.Manager]
  }
];
