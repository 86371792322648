import { createMuiTheme } from '@material-ui/core/styles';
import { defaultColor } from '~/constants/colors';

const {
  black,
  white,
  blue,
  blueAquaVeryLight,
  blueKingstonAqua,
  pastelLightBlue,
  blueDark,
  strongCyan,
  celestial,
  gray,
  grayF3,
  grayF9,
  grayA8,
  gray70,
  grayMaterial,
  blackMaterial,
  lightGray,
  veryLightGray,
  blackGray,
  orange,
  paleOrange,
  orangePeel,
  red,
  redCoralTree,
  veryLightRed,
  redVistaWhite,
  brightPink,
  green,
  veryLightGreen,
  greenPistachio,
  greenLightSea,
  green42,
  yellow,
  yellowDark,
  yellowJasmine,
  softPurple,
  verySoftPurple,
  cornflowerBlue
} = defaultColor;

export default createMuiTheme({
  palette: {
    common: {
      white,
      black,
      blue,
      blueKingstonAqua,
      blueAquaVeryLight,
      pastelLightBlue,
      blueDark,
      celestial,
      gray,
      grayF3,
      grayF9,
      grayA8,
      gray70,
      grayMaterial,
      blackMaterial,
      lightGray,
      veryLightGray,
      blackGray,
      orange,
      paleOrange,
      orangePeel,
      red,
      redCoralTree,
      veryLightRed,
      redVistaWhite,
      brightPink,
      green,
      veryLightGreen,
      greenPistachio,
      greenLightSea,
      green42,
      yellow,
      yellowDark,
      yellowJasmine,
      softPurple,
      verySoftPurple,
      cornflowerBlue
    },
    primary: {
      main: blue
    },
    secondary: {
      main: veryLightGray
    },
    error: {
      main: red
    },
    warning: {
      main: paleOrange
    },
    success: {
      main: green
    },
    background: {
      default: pastelLightBlue
    },
    text: {
      disabled: '#9b9494'
    }
  },
  mixins: {
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    flexBaseline: {
      display: 'flex',
      alignItems: 'baseline'
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    flexEnd: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    flexStart: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    flexStartCenter: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center'
    },
    flexCenterSpaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    flexCenterSpaceAround: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around'
    },
    flexCenterEnd: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    flexEndCenter: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    flexAlignCenter: {
      display: 'flex',
      alignItems: 'center'
    },
    flexCenterCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    whiteCard: {
      minWidth: '275px',
      minHeight: '100px',
      margin: '15px',
      padding: '5px 5px 0 5px',
      boxShadow: '1px 1px 3px #00000029',
      background: white,
      display: 'flex',
      alignItems: 'center'
    },
    editBoxWithRightBorder: {
      borderRight: `3px dashed ${cornflowerBlue}`,
      paddingRight: '0.5rem'
    },
    editBoxWithRightAndLeftBorder: {
      borderRight: `3px dashed ${cornflowerBlue}`,
      borderLeft: `3px dashed ${cornflowerBlue}`,
      padding: '0 0.5rem'
    },
    btnBlueSquare: {
      padding: '6px 16px',
      background: blue,
      '&:hover': {
        background: blueKingstonAqua,
        color: white
      },
      '&:disabled': {
        background: blueAquaVeryLight,
        color: white
      }
    },
    btnPurpleSquare: {
      padding: '6px 16px',
      background: softPurple,
      '&:hover': {
        background: '#a89fcfe6',
        color: white
      },
      '&:disabled': {
        background: verySoftPurple,
        color: white
      }
    },
    btnStrongCyanSquare: {
      padding: '6px 16px',
      background: strongCyan,
      '&:hover': {
        background: blueKingstonAqua,
        color: white
      },
      '&:disabled': {
        background: blueAquaVeryLight,
        color: white
      }
    },
    btnRedSquare: {
      padding: '6px 16px',
      background: red,
      '&:hover': {
        background: redCoralTree,
        color: white
      },
      '&:disabled': {
        background: veryLightRed,
        color: white
      }
    },
    btnInvertedBrightPinkSquare: {
      padding: '6px 16px',
      background: '#FFEAEB',
      '&:hover': {
        background: '#E77883',
        color: white
      },
      '&:disabled': {
        background: veryLightRed,
        color: white
      }
    },
    btnGreenSquare: {
      padding: '6px 16px',
      background: green,
      '&:hover': {
        background: greenPistachio,
        color: white
      },
      '&:disabled': {
        background: veryLightGreen,
        color: white
      }
    },
    btnGraySquare: {
      padding: '6px 16px',
      background: veryLightGray,
      '&:hover': {
        background: veryLightGray,
        color: white
      }
    },
    btnCelestialSquare: {
      padding: '6px 16px',
      background: celestial,
      '&:hover': {
        background: '#a2bbde',
        color: white
      },
      '&:disabled': {
        background: '#d6e8ff',
        color: white
      }
    },
    btnLightGraySquare: {
      padding: '6px 16px',
      background: '#F0F1F3',
      '&:hover': {
        background: '#F0F1F3',
        color: black
      },
      '&:disabled': {
        background: gray,
        color: veryLightGray
      }
    },
    btnTransparent: {
      padding: '6px 16px',
      background: 'transparent',
      '&:hover': {
        background: 'transparent'
      }
    },
    commentTextArea: {
      width: '100%',
      marginTop: '0.7rem',
      padding: '10px',
      border: `1px solid ${veryLightGray}`,
      outline: 'none',
      borderRadius: '4px',
      '&:disabled': {
        color: grayA8
      },
      '&:focus': {
        border: `2px solid ${blue}`
      }
    }
  },
  typography: {
    roboto_13_15: {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '15px'
    },
    roboto_12_14: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '14px'
    },
    roboto_14_17: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '17px'
    },
    roboto_15_17: {
      fontFamily: 'Roboto',
      fontSize: '15px',
      lineHeight: '17px'
    },
    roboto_16_18: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '18px'
    },
    formInputTitle: {
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '18px',
      color: gray
    },
    secondTitle: {
      fontFamily: 'Roboto',
      fontSize: '15px',
      lineHeight: '18px',
      color: gray
    },
    formTitle: {
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontSize: '15px',
      lineHeight: '18px',
      color: blue,
      textTransform: 'uppercase'
    },
    modalTitle: {
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '17px',
      lineHeight: '23px',
      color: gray,
      '&:first-letter': {
        textTransform: 'capitalize'
      }
    },
    cardSmallText: {
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '14px',
      color: blackGray
    },
    cardTableHeader: {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '15px',
      color: grayA8
    },
    legend: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      lineHeight: '17px',
      paddingLeft: '0.5rem',
      color: gray
    },
    btnSquare: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      color: white,
      fontSize: '15px',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      textTransform: 'none'
    },
    btnSquareSmall: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: white,
      fontSize: '13px',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
      textTransform: 'none'
    },
    btnTransparent: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '18px',
      textTransform: 'none',
      color: gray
    },
    commentTextArea: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '23px',
      color: black,
      '&::placeholder': {
        color: lightGray,
        fontFamily: 'Roboto'
      }
    },
    capitalizeFirstLetter: {
      display: 'block',
      '&:first-letter': {
        textTransform: 'capitalize'
      }
    }
  },
  overrides: {}
});
