import { isString } from './shared';

export const sortAscendingByName = (array: any[]) => {
  if (Array.isArray(array)) {
    const newArray = [...array];
    newArray.sort(function ascending(a, b) {
      const nameA = a?.name?.toLowerCase();
      const nameB = b?.name?.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    return newArray;
  }
  return array;
};

export const sortAscendingByNameAccented = (
  array: any[],
  hasToTrim = false
) => {
  if (Array.isArray(array)) {
    const newArray = [...array];
    newArray.sort(function ascendingAccented(a, b) {
      const aName = hasToTrim && isString(a?.name) ? a?.name.trim() : a?.name;
      const bName = hasToTrim && isString(b?.name) ? b?.name.trim() : b?.name;
      return aName?.localeCompare(bName, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    });
    return newArray;
  }
  return array;
};

export const sortAscendingByAccented = (array: any[], objKey: string) => {
  if (Array.isArray(array)) {
    const newArray = [...array];
    newArray.sort(function ascendingAccented(a, b) {
      return a?.[objKey]?.localeCompare(b?.[objKey], undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    });
    return newArray;
  }
  return array;
};

export type Order = 'ASC' | 'DESC';

export const sortBy = (array: any[], type: string, order: Order = 'ASC') => {
  if (Array.isArray(array) && isString(type)) {
    const newArray = [...array];
    newArray.sort(function ascOrDesc(a, b) {
      // @ts-ignore
      const nameA = typeof a == 'string' ? a[type]?.toLowerCase() : a[type];
      // @ts-ignore
      const nameB = typeof b == 'string' ? b[type]?.toLowerCase() : b[type];
      if (nameA < nameB) return order === 'ASC' ? -1 : 1;
      if (nameA > nameB) return order === 'ASC' ? 1 : -1;
      return 0;
    });
    return newArray;
  }
  return array;
};

export const sortByDate = (
  array: any[],
  type: string,
  order: Order = 'ASC'
) => {
  if (Array.isArray(array) && isString(type)) {
    const newArray = [...array];
    newArray.sort(function ascOrDesc(a, b) {
      const nameA = new Date(a[type]);
      const nameB = new Date(b[type]);
      if (nameA < nameB) return order === 'ASC' ? -1 : 1;
      if (nameA > nameB) return order === 'ASC' ? 1 : -1;
      return 0;
    });
    return newArray;
  }
  return array;
};

export const sortNumAscending = (array: number[]) =>
  [...array].sort((a, b) => a - b);

export const sortNumDescending = (array: number[]) =>
  [...array].sort((a, b) => b - a);

export const sortByNested = (
  array: any[],
  objectName: string,
  property: string,
  order: Order = 'ASC'
) => {
  if (Array.isArray(array) && isString(objectName) && isString(property)) {
    const newArray = [...array];
    newArray.sort(function ascOrDesc(a, b) {
      const nameA =
        typeof a == 'string'
          ? // @ts-ignore
            a[objectName][property]?.toLowerCase()
          : a[objectName][property];
      const nameB =
        typeof b == 'string'
          ? // @ts-ignore
            b[objectName][property]?.toLowerCase()
          : b[objectName][property];
      if (nameA < nameB) return order === 'ASC' ? -1 : 1;
      if (nameA > nameB) return order === 'ASC' ? 1 : -1;
      return 0;
    });
    return newArray;
  }
  return array;
};

export const findIdInNestedArray = (
  array: any[],
  objId: string | number | undefined | null
) => {
  // @ts-ignore
  function dfs(obj, targetId) {
    if (obj.id === targetId) {
      return obj;
    }
    if (obj.children) {
      for (const item of obj.children) {
        // @ts-ignore
        const check = dfs(item, targetId);
        if (check) {
          return check;
        }
      }
    }
    return null;
  }

  let result = null;

  for (const item of array) {
    result = dfs(item, objId);
    if (result) {
      break;
    }
  }
  return result;
};

export const isEqualArray = (array1: any[], array2: any[]) => {
  const array2Sorted = array2.slice().sort();
  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every(function x(value, index) {
        return value === array2Sorted[index];
      })
  );
};

export const arrayToObject = (array: any[], key: string) => {
  // e.g [{id:1, etc}, {id: 2, etc}] => {1: {id: 1, etc}, 2: {id: 2, etc}}
  if (Array.isArray(array) && isString(key)) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item
      };
    }, initialValue);
  }
  return {};
};

export const arrayToObjectWithNestedKey = (
  array: any[],
  objectName: string,
  property: string
) => {
  if (Array.isArray(array) && isString(objectName) && isString(property)) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[objectName][property]]: item
      };
    }, initialValue);
  }
  return {};
};

export const arrayGroupBy = (
  arr: any[],
  objectName: string,
  property: string
) => {
  if (Array.isArray(arr) && isString(objectName) && isString(property)) {
    return arr.reduce((obj, item) => {
      return {
        ...obj,
        [item?.[objectName]?.[property]]: [
          ...(obj[item?.[objectName]?.[property]] || []),
          item
        ]
      };
    }, {});
  }
  return {};
};

export const arrayNestedObjectGroupBy = (
  arr: any[],
  objectName: string,
  subObjectName: string,
  property: string
) => {
  if (
    Array.isArray(arr) &&
    isString(objectName) &&
    isString(subObjectName) &&
    isString(property)
  ) {
    return arr.reduce((obj, item) => {
      return {
        ...obj,
        [item?.[objectName]?.[subObjectName]?.[property]]: [
          ...(obj[item?.[objectName]?.[subObjectName]?.[property]] || []),
          item
        ]
      };
    }, {});
  }
  return {};
};

export const removeDuplication = (array: any[], key: string) => {
  if (Array.isArray(array) && array?.length > 0 && isString(key)) {
    const newArray = array.filter(
      (v, i, a) => a.findIndex((x) => x[key] === v[key]) === i
    );
    return newArray;
  }
  return array;
};

export const mergeTwoArrOfObjBasedOnKey = (
  array1: any[],
  array2: any[],
  key: string = 'id'
) => {
  const array = array1.map((t1) => ({
    ...t1,
    ...array2.find((t2) => t2[key] === t1[key])
  }));
  return array;
};

export const compareTwoArraysOfObjectAndGetDifferences = (
  oldArray: any[],
  newArray: any[],
  keyOldArray: string = 'id',
  keyNewArray: string = 'id'
) => {
  const oldArrayIds = oldArray.map((el) => el[keyOldArray]);
  const newArrayIds = newArray.map((el) => el[keyNewArray]);
  const addedElements = newArray.filter(
    (el) => !oldArrayIds.includes(el[keyNewArray])
  );
  const removedElements = oldArray.filter(
    (el) => !newArrayIds.includes(el[keyOldArray])
  );
  // addedElements and removedElements are array of objects
  return { addedElements, removedElements };
};

export const splitArrayIntoChunk = (array: any[], chunkSize: number) => {
  if (Array.isArray(array) && chunkSize) {
    const result = array
      .map((e, i) => {
        return i % chunkSize === 0 ? array.slice(i, i + chunkSize) : null;
      })
      .filter((e) => {
        return e;
      });
    return result;
  }
  return array;
};

export const countOccurrencesInsideArray = (arr: any[]) => {
  if (Array.isArray(arr)) {
    const newArray = arr.reduce(
      /* eslint-disable-next-line */
      (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
      {}
    );
    return newArray;
  }
  return {};
};
