import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    logo: {
      height: '48px',
      width: '130px',
      [theme.breakpoints.down('md')]: {
        width: '100px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100px'
      }
    },
    navbar: {
      position: 'sticky',
      top: 0,
      background: theme.palette.common.pastelLightBlue,
      height: '70px',
      overflow: 'hidden',
      width: '100%',
      zIndex: 1000,
      backgroundColor: theme.palette.common.white,
      padding: '0.7rem 1rem 0.5rem 1rem',
      boxShadow:
        '0 0.46875rem 2.1875rem rgb(59 62 102 / 3%), 0 0.9375rem 1.40625rem rgb(59 62 102 / 3%), 0 0.25rem 0.53125rem rgb(59 62 102 / 5%), 0 0.125rem 0.1875rem rgb(59 62 102 / 3%)'
    },
    grid: {
      width: 'calc(100% - 210px)',
      [theme.breakpoints.down('lg')]: {
        width: 'calc(100% - 185px)'
      },
      [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 175px)'
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 150px)'
      }
    },
    headerNumber: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '26px',
      lineHeight: '48px',
      padding: 0,
      margin: 0
    },
    headerAmount: {
      whiteSpace: 'nowrap',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '21px',
      lineHeight: '32px',
      margin: 0
    },
    numberDescription: {
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '18px',
      color: theme.palette.common.gray,
      padding: '0 0 0 1rem',
      margin: '0 1rem 0 0'
    },
    amountDescription: {
      textAlign: 'center',
      ...theme.typography.roboto_13_15,
      color: theme.palette.common.gray,
      padding: 0,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        marginRight: '5px'
      }
    },
    numbersBox: {
      ...theme.mixins.flexCenterSpaceAround,
      height: '48px',
      [theme.breakpoints.down('md')]: {
        '@supports (-webkit-appearance:none)': {
          zoom: '0.85'
        },
        '@supports (-moz-appearance:none)': {
          transform: 'scale(0.85)',
          transformOrigin: 'center top'
        },
        marginTop: '0.25rem'
      },
      [theme.breakpoints.down('sm')]: {
        '@supports (-webkit-appearance:none)': {
          zoom: '0.7'
        },
        '@supports (-moz-appearance:none)': {
          transform: 'scale(0.7)',
          transformOrigin: 'center top'
        },
        marginTop: '0.9rem',
        marginRight: '0.5rem',
        paddingRight: '1rem',
        paddingLeft: '7px'
      }
    },
    flexCenter: {
      ...theme.mixins.flexCenter
    },
    flexCenterCenter: {
      ...theme.mixins.flexCenterCenter
    },
    flexCenterSpaceBetween: {
      ...theme.mixins.flexCenterSpaceBetween
    },
    purple: {
      color: theme.palette.common.softPurple
    },
    searchBar: {
      width: '100%',
      marginLeft: '5px',
      ...theme.mixins.flexCenterCenter
    },
    searchInput: {
      background: theme.palette.common.white,
      marginTop: '-5px',
      [theme.breakpoints.up('sm')]: {
        width: '200px'
      },
      [theme.breakpoints.up('md')]: {
        width: '110px'
      },
      [theme.breakpoints.up('lg')]: {
        width: '210px'
      },
      [theme.breakpoints.up('xl')]: {
        width: '350px'
      }
    },
    iconStats: {
      position: 'fixed',
      top: '20px',
      right: '115px'
    },
    iconRefreshAndMoreStats: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      top: '10px',
      right: '190px',
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    avatar: {
      position: 'fixed',
      top: '15px',
      right: '15px'
    }
  }),
  { index: 1 } //  optional argument for JSS, to set position after MUI stylesheets
);
