import React from 'react';
import { Link } from 'react-router-dom';
import { filters } from '~/constants/filters';
import { strings } from '~/constants/strings';
import { TodoStatsProps } from '../Header.types';

const TodoStats = ({ classes, todoStats }: TodoStatsProps) => {
  return (
    <>
      {/* Planned Interventions */}
      <Link
        to={filters.linkToInterventionsInProgress}
        style={{ textDecoration: 'none' }}>
        <div className={classes.flexCenterCenter}>
          <p className={`${classes.headerNumber} ${classes.purple}`}>
            {todoStats?.interventionsToBePlanned ?? ''}
          </p>
          <p
            className={classes.numberDescription}
            style={{ maxWidth: '115px' }}>
            {strings.Interventions_en_cours}
          </p>
        </div>
      </Link>
      {/* quotes to sent */}
      <Link to={filters.linkToQuotesToSend} style={{ textDecoration: 'none' }}>
        <div className={classes.flexCenterCenter}>
          <p className={`${classes.headerNumber} ${classes.purple}`}>
            {todoStats?.quoteTodos ?? ''}
          </p>
          <p className={classes.numberDescription} style={{ maxWidth: '90px' }}>
            {strings.Devis_a_transmettre}
          </p>
        </div>
      </Link>
      {/* Visit reports to sent */}
      <Link
        to={filters.linkToVisitReportsToSend}
        style={{ textDecoration: 'none' }}>
        <div className={classes.flexCenterCenter}>
          <p className={`${classes.headerNumber} ${classes.purple}`}>
            {todoStats?.visitReportTodos ?? ''}
          </p>
          <p
            className={classes.numberDescription}
            style={{ maxWidth: '140px' }}>
            {strings.Rapports_de_visite_a_transmettre}
          </p>
        </div>
      </Link>
      {/* invocies to sent */}
      <Link
        to={filters.linkToInvoicesToSend}
        style={{ textDecoration: 'none' }}>
        <div className={classes.flexCenterCenter}>
          <p className={`${classes.headerNumber} ${classes.purple}`}>
            {todoStats?.invoiceTodos ?? ''}
          </p>
          <p className={classes.numberDescription} style={{ maxWidth: '90px' }}>
            {strings.Factures_a_transmettre}
          </p>
        </div>
      </Link>
    </>
  );
};

export default TodoStats;
