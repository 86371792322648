export const strings = {
  client: 'client',
  client_number: 'Numéro client',
  Clients: 'Clients',
  Domaine_d_activité: "Domaine d'activité",
  Secteur_d_activité: "Secteur d'activité",
  domaine_d_activité: "domaine d'activité",
  secteur_d_activité: "secteur d'activité",
  Client: 'Client',
  Adresse: 'Adresse',
  Complement_d_adresse: "Complément d'adresse",
  Code_postal: 'Code postal',
  Ville: 'Ville',
  Groupe: 'Groupe',
  groupe: 'groupe',
  Sous_groupe: 'Sous-groupe',
  sous_groupe: 'sous-groupe',
  Nom: 'Nom',
  Telephone: 'Téléphone',
  Adresse_mail: 'Adresse mail',
  N_de_client: 'N° de client',
  N_du_contrat: 'N° du contrat',
  SIREN: 'SIREN',
  SIRET: 'SIRET',
  contact_principal: 'Contact principal',
  siege_social: 'siège social',
  Siege_social: 'Siège social',
  Veuillez_saisir_le: 'Veuillez saisir le',
  Veuillez_saisir_l_agence: "Veuillez saisir l'agence",
  Aucune_correspondance: 'Aucune correspondance',
  Ajouter: 'Ajouter',
  Annuler: 'Annuler',
  Ajouter_un: 'Ajouter un',
  Ajouter_une: 'Ajouter une',
  Supprimer: 'Supprimer',
  Parent: 'Parent',
  contrat: 'contrat',
  Num: 'N°',
  Nombre_de_sites: 'Nombre de site(s)',
  Date_de_signature: 'Date de signature',
  Durée_du_contrat: 'Durée du contrat',
  Date_de_prise_d_effet: "Date de prise d'effet",
  Alerte_automatique: 'Alerte automatique',
  Date_de_fin_du_contrat: 'Date de fin du contrat',
  Tacite_reconduction: 'Tacite reconduction',
  Période_CE: 'Période CE',
  Ajouter_une_période: 'Ajouter une période',
  Référencement: 'Référencement',
  Contrat_cadre: 'Contrat cadre',
  BPU_curatif: 'BPU curatif',
  BPU_CE: 'BPU CE',
  Oui: 'Oui',
  Non: 'Non',
  RFA: 'RFA',
  Valeurs: 'Valeurs',
  Valeurs_RFA: 'Valeurs RFA',
  Ajouter_des_valeurs: 'Ajouter des valeurs',
  Délai_de_règlement: 'Délai de règlement',
  Mode_de_règlement: 'Mode de règlement',
  Le_client_autorise_la_relance_réception_de_facture_j5:
    'Le client autorise la "relance réception de facture" à J+5',
  signataire_pv_reception: 'signataire pv réception',
  Selectionnez_dans_la_liste: 'Sélectionnez dans la liste',
  contact_comptable: 'contact comptable',
  contact_relance_facture: 'contact relance facture',
  adresse_de_facturation: 'Adresse de facturation',
  ajouter_un_contact_supplémentaire: 'Ajouter un contact supplémentaire',
  ajouter_un_adresse_supplémentaire: 'Ajouter un adresse supplémentaire',
  ajouter_un_signataire_supplémentaire: 'Ajouter un signataire supplémentaire',
  Exclusif: 'Exclusif',
  Non_exclusif: 'Non exclusif',
  jrs: 'jrs',
  Début: 'Début',
  Fin: 'Fin',
  Enregistrer: 'Enregistrer',
  Attention: 'Attention',
  Etes_vous_sur_de_vouloir: 'Êtes-vous sûr de vouloir',
  supprimer: 'supprimer',
  cet_element: 'cet élément',
  Upload_en_cours: 'Upload en cours',
  Ajouter_un_fichier: 'Ajouter un fichier',
  extension_fichier_non_autorisee_etc:
    "Extension de fichier non autorisée, l'extension de fichier doit être de type",
  batiment: 'bâtiment',
  Batiment: 'Bâtiment',
  proprietaire_actuel: 'propriétaire actuel',
  locataire_actuel: 'locataire actuel',
  agences_intervenantes: 'agences intervenantes',
  Derniere_intervention: 'Dernière intervention',
  Objet: 'Objet',
  Reference: 'Référence',
  historique: 'historique',
  entretien: 'entretien',
  travaux: 'travaux',
  Commentaires: 'Commentaires',
  Ajouter_un_commentaire: 'Ajouter un commentaire',
  Envoyer: 'Envoyer',
  documents_relatifs_au_bâtiment: 'documents relatifs au bâtiment',
  historique_de_propriété: 'historique de propriété',
  Nouveau_propriétaire: 'Nouveau propriétaire',
  Du: 'Du',
  au: 'au',
  Depuis_le: 'Depuis le',
  Date_du_debut: 'Date du début',
  Nouveau_locataire: 'Nouveau locataire',
  historique_de_location: 'historique de location',
  intervention_sur_ce_batiment: 'Intervention sur ce bâtiment',
  Sujet: 'Sujet',
  Responsable: 'Responsable',
  Valider: 'Valider',
  historique_du_batiment: 'historique du bâtiment',
  fiche_client: 'fiche client',
  attribution_des_clients: 'attribution des clients',
  Attribuer: 'Attribuer',
  Attribué: 'Attribué',
  Non_attribué: 'Non attribué',
  Distance_du_chantier: 'Distance du chantier',
  Amiante: 'Amiante',
  contact_agence: 'contact agence',
  Séléctionner: 'Séléctionner',
  Devis_type_ce: 'Devis type CE',
  Devis_diag: 'Devis diag',
  Devis_urgence_infiltration: 'Devis urgence infiltration',
  Informations_santé_de_l_agence: "Informations santé de l'agence",
  Commentaire_sur_l_agence: "Commentaire sur l'agence",
  Interventions_en_cours: 'Interventions en cours',
  Devis_a_transmettre: 'Devis à transmettre',
  Rapports_de_visite_a_transmettre: 'Rapports de visite à transmettre',
  Interventions_facturées: 'Interventions facturées',
  CA_du_mois_en_cours: 'CA du mois en cours',
  CA_du_mois_en_cours_contrats_d_entretien: `CA du mois en cours : CE`,
  CA_du_mois_en_cours_curatif: 'CA du mois en cours : curatif',
  signature_cf: 'signature CF',
  ct_st_signé: 'ct st signé',
  signature_bl: 'signature bl',
  annexes_total_4: 'annexes total 4',
  charte_gc: 'charte gc',
  attestation_salariés_étrangers: 'attestation salariés étrangers',
  RCD: 'RCD',
  ringeval_2nd_ligne: 'ringeval 2nd ligne',
  KBIS: 'KBIS',
  URSSAF: 'URSSAF',
  D1M: 'D1M',
  carte_pro_franchisé: 'carte pro franchisé',
  visite_agence: 'visite agence',
  amiante_SS4: 'amiante SS4',
  sortie_réseau: 'Sortie réseau',
  commentaire: 'commentaire',
  Eligible: 'Éligible',
  Eligible_pour_le_moment: 'Éligible pour le moment',
  Non_éligible: 'Non éligible',
  administration_des_agences_attila: 'administration des agences attila',
  Dernière_visite: 'Dernière visite',
  Date_de_visite: 'Date de visite',
  Historique_des_visites: 'Historique des visites',
  Nouvelle_date: 'Nouvelle Date',
  Nouveau_commentaire: 'Nouveau commentaire',
  Aucun_commentaire: 'Aucun commentaire',
  Message: 'Message',
  Message_interne: 'Message interne',
  Décideur: 'Décideur',
  Tous: 'Tous',
  Nacelle: 'Nacelle',
  Contact_agence: 'Contact agence',
  Priorité: 'Priorité',
  Contact_AGC: 'Contact AGC',
  Devis: 'Devis',
  Types_de_devis: 'Types de devis',
  Ajouter_une_intervention: 'Ajouter une intervention',
  Lignes_d_intervention: "Lignes d'intervention",
  Documents: 'Documents',
  Grands_comptes: 'Grands comptes',
  Agence: 'Agence',
  Rendez_vous: 'Rendez-vous',
  Nouveau: 'Nouveau',
  Contact: 'Contact',
  Date_du_rdv: 'Date du RDV',
  Masquer_les_anciens_rdv: 'Masquer les anciens RDV',
  contact: 'contact',
  Chargement_en_cours: 'Chargement en cours...',
  Calcul_des_distances_en_cours: 'Calcul des distances en cours',
  Veuillez_patienter: 'Veuillez patienter',
  ceci_peut_prendre_plusieurs: 'ceci peut prendre plusieurs',
  secondes: 'secondes',
  Erreur: 'Erreur',
  à: 'à',
  En_cours: 'En cours',
  sur: 'sur',
  lignes_par_page: 'Lignes par page',
  contact_AGC: 'contact AGC',
  Batiments: 'Bâtiments',
  Gérer_les_contrats_d_entretien: "Gérer les contrats d'entretien",
  Montant_agence: 'Montant agence',
  Montant_nacelle: 'Montant nacelle',
  Menus_travaux_Max_lors_du_CE: 'Menus travaux Max lors du CE',
  Champ_libre: 'Champ libre',
  Période: 'Période',
  Renouvellement: 'Renouvellement',
  Montant: 'Montant',
  Renouveler_les_contrats: 'Renouveler les contrats',
  Renouveler_le_contrat: 'Renouveler le contrat',
  Créer_les_lignes_nouveaux_contrats: 'Créer les lignes (nouveaux contrats)',
  Actions: 'Actions',
  Note: 'Note',
  Commune: 'Commune',
  Trier_par: 'Trier par',
  Ajouter_un_nouveau_client: 'Ajouter un nouveau client',
  Gérer_les_sous_traitants: 'Gérer les sous-traitants',
  Afficher_toutes_les_données: 'Afficher toutes les données',
  Créer_une_ligne_nouveau_contrat: 'Créer une ligne (nouveau contrat)',
  Sauvegarder: 'Sauvegarder',
  Sauvegarder_tous: 'Sauvegarder (tous)',
  Annuler_tous: 'Annuler (tous)',
  Bugdet_menus_travaux_lors_du_CE: 'Bugdet menus travaux lors du CE',
  Oui_mais_aucun_justificatif_n_a_été_ajouté:
    "Oui, mais aucun justificatif n'a été ajouté",
  Commentaire_interne: 'Commentaire interne',
  date_du_contact: 'Date du contact',
  Masquer_les_anciens_contacts: 'Masquer les anciens contacts',
  Documents_communs: 'Documents communs',
  aucune_donnée: 'aucune donnée',
  Liste_des_documents: 'Liste des documents',
  Groupe_sélectionné: 'Groupe sélectionné',
  Aucun: 'Aucun',
  Aucune: 'Aucune',
  Relance: 'Relance',
  Enregistré_avec_succès: 'Enregistré avec succès',
  Enregistrement_en_cours: 'Enregistrement en cours...',
  OK: 'OK',
  erreur_lors_de_la_récupération_des_informations_depuis_le_serveur:
    'Erreur lors de la récupération des informations depuis le serveur.',
  Code_Erreur: 'Code Erreur',
  sur_ce_batiment: 'sur ce bâtiment',
  CE: 'CE',
  Vide: 'Vide',
  Annulé: 'Annulé',
  Facturée: 'Facturée',
  A_traiter: 'À traiter',
  Dupliquer: 'Dupliquer',
  Rafraichir: 'Rafraîchir',
  Ajouter_des_documents_pour_ce: 'Ajouter des documents pour ce',
  Aucune_donnée_fournie: 'Aucune donnée fournie',
  sur_le_propriétaire_actuel: 'sur le propriétaire actuel',
  sur_le_locataire_actuel: 'sur le locataire actuel',
  Voir_l_historique: "Voir l'historique",
  Ajouter_un_batiment: 'Ajouter un bâtiment',
  Latitude: 'Latitude',
  Longitude: 'Longitude',
  veuillez_déterminer_la_position_sur_la_carte_requis:
    'Veuillez déterminer la position sur la carte (requis).',
  Vous_pouvez_utiliser_la_barre_de_recherche_au_dessus_de_la_carte:
    'Vous pouvez utiliser la barre de recherche au-dessus de la carte',
  Nom_du_batiment: 'Nom du bâtiment',
  Type_de_contrat: 'Type de contrat',
  Date_de_la_fin: 'Date de la fin',
  Fonction: 'Fonction',
  Rechercher: 'Rechercher',
  non_déterminée: 'non déterminée',
  local: 'local',
  central: 'central',
  actif: 'actif',
  Enregistrer_les_modifications: 'Enregistrer les modifications',
  tout: 'tout',
  existant: 'existant',
  non_existant: 'non existant',
  envoyé: 'envoyé',
  non_envoyé: 'non envoyé',
  fait: 'fait',
  faite: 'faite',
  non_fait: 'non fait',
  non_faite: 'non faite',
  Annulé_Perdu: 'Annulé/Perdu',
  enregistré: 'enregistré',
  non_enregistré: 'non enregistré',
  date_renseignée: 'date renseignée',
  date_non_renseignée: 'date non renseignée',
  attribution: 'attribution',
  RDV: 'RDV',
  prise_de_conatct: 'Prise de contact',
  devis: 'devis',
  planif_intervention: 'Planif intervention',
  facturation: 'facturation',
  année: 'année',
  états: 'états',
  Contact_RDV: 'Contact/RDV',
  rapport_de_visite: 'rapport de visite',
  facture: 'facture',
  accord_commande: 'accord commande',
  planificateur_intervention: 'Planificateur Intervention',
  relances: 'relances',
  Réinitialiser: 'Réinitialiser',
  Cacher_recherche_avancée: 'Cacher la recherche avancée',
  Afficher_recherche_avancée: 'Afficher la recherche avancée',
  Date_d_echeance: "Date d'échéance",
  Signataire: 'Signataire',
  Reçois_les_notifications: 'Reçois les notifications',
  Reçois_les_relances: 'Reçois les relances',
  contacts: 'contacts',
  voir_modifier_ajouter: 'Voir/Modifier/Ajouter',
  Nombre_de_contacts_disponibles: ' Nombre de contacts disponibles',
  ajouter_un_contact: 'Ajouter un contact',
  ajouter_un_signataire: 'Ajouter un signataire',
  Cette_icone_indique_le_dernier_contact_et_la_derniere_agence_etc:
    'Cette icône indique le dernier contact et la dernière agence attribuée lors de la dernière intervention sur ce bâtiment',
  pour_tous_les_batiments_du_client: 'pour tous les bâtiments du client',
  pour_ce_batiment: 'pour ce bâtiment',
  Interventions_filtrées_par_référence: 'Interventions filtrées par référence',
  Cette_page_ne_semble_pas_exister: 'Cette page ne semble pas exister',
  Attribution: 'Attribution',
  date_de_demande: 'date de demande',
  commande: 'commande',
  objet_et_message: 'objet et message',
  plan_prévention: 'plan prévention',
  PV_réception: 'PV réception',
  début_des_travaux_prévu: 'début des travaux prévu',
  date_contact: 'Date contact',
  Date_RDV: 'Date RDV',
  SAV: 'SAV',
  Ajouter_une_date: 'Ajouter une date',
  Soumis_à: 'Soumis à',
  un_numéro_de_commande: 'un numéro de commande',
  un_accord_client: 'un accord client',
  Toutes_les_interventions: 'Toutes les interventions',
  NAF: 'NAF',
  Nom_commercial: 'Nom commercial',
  Nom_commercial_eg:
    "Nom commercial et non pas le nom d'établissement (par exemple: franprix)",
  Nom_leader: 'Nom leader',
  Leader_nom_d_un_dirigeant_statutaire:
    "Leader (nom d'un dirigeant statutaire)",
  Titre_Leader: 'Titre leader',
  Titre_d_un_dirigeant_statutaire:
    "Titre d'un dirigeant statutaire (par exemple: commissaire aux comptes)",
  Descriptif_de_la_société: 'Descriptif de la société',
  Activité_eg: 'Activité (par exemple: prospection)',
  Compétences_au_sein_de_l_entreprise: "Compétences au sein de l'entreprise",
  Compétences_au_sein_de_l_entreprise_eg:
    "Compétences au sein de l'entreprise (par exemple: comptable)",
  Cacher_les_filtres: 'Cacher les filtres',
  Afficher_les_filtres: 'Afficher les filtres',
  Recherche_avancée: 'Recherche avancée',
  du_siege_social_uniquement: 'du siège social uniquement',
  Non_déterminée: 'Non déterminée',
  Effectifs: 'Effectifs',
  Chiffre_d_affaire: "Chiffre d'affaire",
  Raison_sociale_ou_un_nom_d_établissement:
    "Raison sociale ou un nom d'établissement",
  Date_de_création: 'Date de création',
  Status_du_goupe: 'Status du groupe',
  Code_NAF: 'Code NAF',
  Intitulé_de_la_NAF: 'Intitulé de la NAF',
  Site_web: 'Site web',
  L_établissement_est_actif: "L'établissement est actif",
  Acronyme: 'Acronyme',
  Actif: 'Actif',
  N_D: 'N/D',
  Achat_fiche: 'Achat fiche',
  vous_attribuer_cette_fiche_client: 'vous attribuer cette fiche client',
  Nombre_d_établissements_actifs: "Nombre d'établissements actifs",
  Résultat_net_Bénéfice_ou_Perte: 'Résultat net (Bénéfice ou Perte)',
  Liste_des_établissements_actifs: 'Liste des établissements actifs',
  Details_de_la_fiche_attribuee: 'Détails de la fiche attribuée',
  Veuillez_choisir_le_domaine_d_activité_et_le_secteur_d_activité_du_client:
    "Veuillez choisir le domaine d'activité et le secteur d'activité du client",
  gérer_les_contacts: 'Gérer les contacts',
  Retour: 'Retour',
  Continuer_vers: 'Continuer vers',
  l_assitant_de_création_de_la_fiche_client:
    "l'assitant de création de la fiche client",
  Continuer: 'Continuer',
  Terminer: 'Terminer',
  Prénom: 'Prénom',
  Type_de_fonction: 'Type de fonction',
  Veuillez_indiquer_le_type_de_fonction_pour_chaque_contact_Éditer_etc:
    'Veuillez indiquer le type de fonction pour chaque contact. Éditer, ajouter ou supprimer les contacts non pertinents',
  Voir_la_dernière_fiche_attribuée: 'Voir la dernière fiche attribuée',
  Le_type_de_fonction_pour_chaque_contact_est_obligatoire:
    'Le type de fonction pour chaque contact est obligatoire.',
  Les_modifications_n_ont_pas_été_enregistrées_etes_vous_sur_de_vouloir_quitter_cette_page:
    "Les modifications n'ont pas été enregistrées. Êtes-vous sûr de vouloir quitter cette page ?",
  Pourcentage: 'Pourcentage',
  Montant_avant_taux_de_marque: 'Montant avant taux de marque',
  Montant_après_taux_de_marque: 'Montant après taux de marque',
  Informations_générales: 'Informations générales',
  Photos: 'Photos',
  Articles: 'Articles',
  Article_N: 'Article N°',
  Titre: 'Titre',
  un_article: 'un article',
  Adresse_du_chantier: 'Adresse du chantier',
  Quantité: 'Quantité',
  Unité: 'Unité',
  Prix_Unitaire: 'Prix Unitaire',
  Totaux: 'Totaux',
  minutes: 'minutes',
  heures: 'heures',
  Temps_unitaire: 'Temps unitaire',
  Temps_total: 'Temps total',
  Listes_des_opérations: 'Liste des opérations',
  Quantité_totale: 'Quantité totale',
  Type: 'Type',
  Quantité_perdu: 'Quantité perdu',
  Prix_achat: 'Prix achat',
  Prix_vente: 'Prix vente',
  détails_fournitures: 'Détails fournitures',
  Sous_total_fournitures_prix_HT: 'Sous-total fournitures prix HT',
  Taux_de_TVA: 'Taux de TVA',
  Titre_opérations: 'Titre (opérations)',
  Opération: 'Opération',
  Editer: 'Éditer',
  Ajouter_un_titre: 'Ajouter un titre',
  Ajouter_une_operation: 'Ajouter une opération',
  Ajouter_des_photos: 'Ajouter des photos',
  Monsieur: 'Monsieur',
  Madame: 'Madame',
  M: 'M.',
  Mme: 'Mme',
  Comptable: 'Comptable',
  Dernier_commentaire: 'Dernier commentaire',
  Procéder_au_traitement: 'Procéder au traitement',
  Ajouter_Importer_des_batiments: 'Ajouter / Importer des bâtiments',
  Ajouter_des_batiments: 'Ajouter des bâtiments',
  Importer_des_batiments: 'Importer des bâtiments',
  Nombre_de_batiments: 'Nombre de bâtiments',
  Les_contacts_agences_sont_aussi_les_signataires:
    'Les contacts agences sont aussi les signataires.',
  la_fonction_de_tous_les_contacts_agences:
    'la fonction de tous les contacts agences',
  exemple_chef_de_centre: 'exemple: chef de centre',
  Positions_trouvées: 'Positions trouvées',
  positions_n_ont_pas_été_trouvées_vous_devez_le_faire_manuellement:
    "positions n'ont pas été trouvées, vous devez le faire manuellement.",
  Corriger: 'Corriger',
  Suivant: 'Suivant',
  Deuxième_tentative_pour_les_positions_manquantes:
    'Deuxième tentative pour les positions manquantes',
  Modifier_un_batiment: 'Modifier un bâtiment',
  Veuillez_remplir_les_données_manquantes:
    'Veuillez remplir les données manquantes.',
  Contexte_amiante: 'Contexte amiante',
  Veuillez_choisir_le_nom_de_la_colonne_correspondant_dans_le_fichier_excel:
    'Veuillez choisir le nom de la colonne correspondant dans le fichier excel.',
  Devis_général_curatif: 'Devis général (curatif)',
  Devis_général_curatif_sans_infiltration:
    'Devis général (curatif) - sans infiltration',
  Devis_général_curatif_avec_infiltration:
    'Devis général (curatif) - avec infiltration',
  Devis_général_sans_infiltration: 'Devis général sans infiltration',
  Devis_général_avec_infiltration: 'Devis général avec infiltration',
  Devis_type_CE: 'Devis type CE',
  Devis_urgence_infiltrations: 'Devis urgence infiltrations',
  Nombre_de_signataires_disponibles: 'Nombre de signataires disponibles',
  Afficher_la_carte: 'Afficher la carte',
  Etat: 'État',
  Ancien_client_ATTILA_Grands_Comptes: 'Ancien client ATTILA Grands Comptes',
  Prospection_et_offre_en_direct_possible:
    'Prospection et offre en direct possible',
  Visite_de_courtoisie: 'Visite de courtoisie',
  Autorisation_de_se_presenter_mais_les_offres_sont_transmises_par_AGC_uniquement_à_la_demande_du_client:
    'Autorisation de se presenter, mais les offres sont transmises par AGC uniquement à la demande du client',
  Aucune_démarche_possible: 'Aucune démarche possible',
  Le_client_ne_souhaite_pas_que_les_agences_se_présentent:
    'le client ne souhaite pas que les agences se présentent',
  Centralisation_activité_existante: 'Centralisation activité existante',
  Assistante_GC_referente: 'Assistant(e) GC référent(e)',
  Logo: 'Logo',
  Signataires: 'Signataires',
  signataire: 'signataire',
  Accueil: 'Accueil',
  L_adresse_de_facturation_par_defaut_correspond_a_l_adresse_du_batiment:
    "L'adresse de facturation, par défaut, correspond à l'adresse du bâtiment",
  L_adresse_de_facturation_par_defaut_correspond_a_l_adresse_ou_une_des_adresses_du_client:
    "L'adresse de facturation, par défaut, correspond à l'adresse ou une des adresses du client",
  L_adresse_de_facturation_par_defaut_correspond_a_l_adresse_d_un_autre_batiment:
    "L'adresse de facturation, par défaut, correspond à l'adresse d'un autre bâtiment",
  Autre_adresse: 'Autre Adresse',
  Libellé: 'Libellé',
  Libellé_de_facturation: 'Libellé de facturation',
  Nom_du_client: 'Nom du client',
  Principale: 'Principale',
  Ajouter_une_addresse_de_facturation: 'Ajouter une addresse de facturation',
  Adresses_de_facturation_principales: 'Adresses de facturation principales',
  Nombre_d_adresses_disponibles: "Nombre d'adresses disponibles",
  Adresses_de_facturation: 'Adresses de facturation',
  Veuillez_saisir_l_adresse: "Veuillez saisir l'adresse",
  Interlocuteur: 'Interlocuteur',
  Se_connecter: 'Se connecter',
  Vérification_en_cours: 'Vérification en cours',
  mot_de_passe: 'mot de passe',
  Veuillez_entrer_votre: 'Veuillez entrer votre',
  email: 'email',
  Mot_de_passe_oublié: 'Mot de passe oublié',
  Connectez_vous_à_votre_Attila_Grands_comptes:
    'Connectez-vous à Attila Grands Comptes',
  Envoyer_le_lien_de_reinitialiation: 'Envoyer le lien de réinitialiation',
  Dénomination: 'Dénomination',
  Contact_client: 'Contact client',
  informations_entete: 'informations entête',
  Verouiller: 'Verouiller',
  Déverrouiller: 'Déverrouiller',
  Aperçu: 'Aperçu',
  Valider_et_Exporter: 'Valider & exporter',
  Description: 'Description',
  Prix_UHT: 'Prix UHT',
  Total: 'Total',
  sous_total: 'sous-total',
  Facture_N: 'Facture N°',
  Référence_devis: 'Référence devis',
  N_de_commande: 'N° de commande',
  Date: 'Date',
  Chantier: 'Chantier',
  Nature_du_Chantier: 'Nature du Chantier',
  Avertissement: 'Avertissement',
  Préparation_du_fichier_en_cours: 'Préparation du fichier en cours',
  Une_erreur_est_survenue_lors_de_la_finalisation_du_processus:
    'Une erreur est survenue lors de la finalisation du processus',
  Télécharger: 'Télécharger',
  Grand_compte: 'Grand compte',
  Montant_génération_du_devis: 'Montant génération du devis',
  Champ_avertissement: 'Champ avertissement',
  Dénomination_du_chantier: 'Dénomination du chantier',
  Nature_des_travaux: 'Nature des travaux',
  Article: 'Article',
  Titre_de_l_article: "Titre de l'article",
  Prix: 'Prix',
  Fournitures: 'Fournitures',
  HT: 'HT',
  TVA: 'TVA',
  TTC: 'TTC',
  Date_action: 'Date action',
  Date_relance: 'Date relance',
  Utilisateur: 'Utilisateur',
  type_de_toiture: 'type de toiture',
  Type_de_travaux: 'Type de travaux',
  Cout_horaire: 'Coût horaire',
  Montant_de_la_remise: 'Montant de la remise',
  Acompte_de_prévu: 'Acompte de prévu',
  Acompte_versé: 'Acompte versé',
  Temps_réel_passé_pour_le_chantier: 'Temps réel passé pour le chantier',
  Référence_au_devis: 'Référence au devis',
  Création: 'Création',
  Entretien: 'Entretien',
  Réfection: 'Réfection',
  Réparation: 'Réparation',
  Poste_de_travail: 'Poste de travail',
  Marge: 'Marge',
  Marge_franchiseur: 'Marge franchiseur',
  quantité_fourniture: 'quantité fourniture',
  Total_titre: 'Total titre',
  Cout_total_des_fournitures_du_titre: 'Coût total des fournitures du titre',
  Temps_total_du_titre: 'Temps total du titre',
  Tuiles_mécaniques: 'Tuiles mécaniques',
  Tuiles_plates: 'Tuiles plates',
  Ardoises: 'Ardoises',
  Bac_acier: 'Bac acier',
  Ètanchéité: 'Ètanchéité',
  Zinc: 'Zinc',
  Cuivre: 'Cuivre',
  Tuiles_béton: 'Tuiles béton',
  Plaques_fibro_béton: 'Plaques fibro-béton',
  Tuiles_canal: 'Tuiles canal',
  Janvier: 'Janvier',
  Février: 'Février',
  Mars: 'Mars',
  Avril: 'Avril',
  Mai: 'Mai',
  Juin: 'Juin',
  Juillet: 'Juillet',
  Aout: 'Août',
  Septembre: 'Septembre',
  Octobre: 'Octobre',
  Novembre: 'Novembre',
  Décembre: 'Décembre',
  Devis_N: 'Devis N°',
  Déverrouillé_avec_succès: 'Déverrouillé avec succès',
  Remise: 'Remise',
  Ajouter_le_nom_de_la_ville_a_la_fin_de_la_fonction_du_contact:
    'Ajouter le nom de la ville à la fin de la fonction du contact',
  Ajouter_un_evenement: 'Ajouter un événement',
  Cacher_les_weekends: 'Cacher les week-ends',
  Agrandir: 'Agrandir',
  Envoyer_une_notifcation_au_contact: 'Envoyer une notification au contact',
  Interlocuteurs: 'Interlocuteurs',
  Je_veux_bien_envoyer_ce_message: 'Je veux bien envoyer ce message',
  Le_message_que_vous_allez_envoyer_au_contact_contient_un_langage_inapproprié:
    'Le message que vous allez envoyer au contact contient un langage inapproprié',
  Afficher_le_calendrier: 'Afficher le calendrier',
  Cacher_le_calendrier: 'Cacher le calendrier',
  Modifier_un_evenement: 'Modifier un événement',
  Toute_la_journée: 'Toute la journée',
  Réduire: 'Réduire',
  Mode_de_communication: 'Mode de communication',
  Veuillez_saisir_l_interlocuteur: "Veuillez saisir l'interlocuteur",
  Contacts: 'Contacts',
  Date_de_déclaration: 'Date de déclaration',
  Date_debut_travaux: 'Date début travaux',
  Divers_documents: 'Divers documents',
  Présence_CSPS_requise: 'Présence CSPS requise',
  plan_prévention_actif: 'Plan prévention actif',
  Options_avancées: 'Options avancées',
  Provenance: 'Provenance',
  Édition: 'Édition',
  Achat: 'Achat',
  Vente: 'Vente',
  Requis: 'Requis',
  Donnée_manquante: 'Donnée manquante',
  suivi_du_dossier: 'suivi du dossier',
  aucune_opération_ne_contient_le_type_de_toiture:
    'aucune opération ne contient le type de toiture',
  Ajouter_une_note: 'Ajouter une note',
  Renommer_un: 'Renommer un',
  Nouveau_nom: 'Nouveau nom',
  Veuillez_saisir_l_assistante_gc_referente:
    "Veuillez saisir l'assistant(e) GC référent(e)",
  Problème: 'Problème',
  Solution: 'Solution',
  Titre_probleme_solution: 'Titre problème / solution',
  Conclusion: 'Conclusion',
  Rapport_de_visite_N: 'Rapport de visite N°',
  terminé: 'terminé',
  date_de_réalisation: 'date de réalisation',
  Relance_téléphonique: 'Relance téléphonique',
  Relance_par_mail: 'Relance par mail',
  une_relance_téléphonique_a_èté_effectuée_le:
    'une relance téléphonique a èté effectuée, le',
  Numero_d_intervention: "Numero d'intervention",
  Date_de_passage: 'Date de passage',
  Devis_suite_CE: 'Devis suite CE',
  Site: 'Site',
  Exporter_les_interventions: 'Exporter les interventions',
  Agence_Attila: 'Agence Attila',
  Enseigne: 'Enseigne',
  Creer_une_intervention_suite_CE: 'Créer une intervention suite CE',
  Creer_une_intervention_menus_travaux: ' Créer une intervention menus travaux',
  Menu_travaux_suite_CE: 'Menu travaux suite CE',
  Intervention_suite_CE: 'Intervention suite CE',
  Intervention_référente: 'Intervention référente',
  Menus_travaux: 'Menus travaux',
  Téléchargement_en_cours: 'Téléchargement en cours',
  Numero_demande_d_intervention_client: "Numéro demande d'intervention client",
  DI_N: 'DI N°',
  Suggerer_les_adresses_probables: 'Suggérer les adresses probables',
  Les_contacts_agences_sont_aussi_les_contacts_GC:
    'Les contacts agences sont aussi les contacts GC',
  Exporter_tous: 'Exporter tous',
  intervention: 'intervention',
  numero: 'numéro',
  Montant_HT: 'Montant HT',
  Exporter_selection_de_la_periode: 'Exporter - sélectionner la période',
  ce_mois: 'ce mois',
  cette_annee: 'Cette année',
  le_mois_dernier: 'le mois dernier',
  l_annee_derniere: " l'année dernière",
  personnaliser: 'personnaliser',
  Bon_de_commande: 'Bon de commande',
  du: 'du',
  Attention_un_montant_semble_etre_present_dans_la_description:
    'Attention : un montant semble être présent dans la description',
  Switcher_vers_proprietaire_locataire:
    'Switcher vers propriétaire / locataire',
  Switcher_vers_contacts: 'Switcher vers contacts',
  proprietaire: 'propriétaire',
  locataire: 'locataire',
  ancien_locataire: 'ancien locataire',
  ancien_proprietaire: 'ancien propriétaire',
  Montant_du_devis: 'Montant du devis',
  Type_de_devis: 'Type de devis',
  Temps_prévu: 'Temps prévu',
  Suivi: 'Suivi',
  Info: 'Info',
  Gestionnaire_de_devis: 'Gestionnaire de devis',
  Nom_ou_prenom_du_contact: 'Nom ou prénom du contact',
  Tout: 'Tout',
  Par_année: 'Par année',
  Ville_code_postal_du_client: 'Ville / code postal du client',
  Gagné: 'Gagné',
  Facturé: 'Facturé',
  Perdu: 'Perdu',
  Correction: 'Correction',
  Gagné_non_facturé: 'Gagné non facturé',
  version: 'version',
  Nombre_total_de_devis: 'Nombre total de devis',
  Commande_total_à_réaliser: 'Commande total à réaliser',
  Total_Heures_de_travail: 'Total Heures de travail',
  soit: 'soit',
  heures_de_travail: 'heures de travail',
  Type_d_intervention: "Type d'intervention",
  Type_ce: 'Type CE',
  Diag: 'Diag',
  Urgence_infiltration: 'Urgence infiltration',
  Général_sans_infiltration: 'Général sans infiltration',
  Général_avec_infiltration: 'Général avec infiltration',
  L_adresse_de_facturation_correspond_a_l_adresse_du_siege_social:
    "L'adresse de facturation correspond à l'adresse du siège social",
  Afficher_tous_les_adresses: 'Afficher tous les adresses',
  Restaurer: 'Restaurer',
  restaurer: 'restaurer',
  Veuillez_ajouter_la_date_de_la_fin_sur_la_fiche_du:
    'Veuillez ajouter la date de la fin sur la fiche du',
  dernier_locataire: 'dernier locataire',
  dernier_propriétaire: 'dernier propriétaire',
  avant_d_jouter_un_nouveau: "avant d'ajouter un nouveau...",
  Rapport_de_visite: 'Rapport de visite',
  Plan_prévention: 'Plan prévention',
  Email: 'E-mail',
  date_limite: 'date limite',
  relance_par_téléphone: 'relance par téléphone',
  relance_par_mail: 'relance par mail',
  Prise_de_contact: 'Prise de contact',
  Prise_de_rendez_vous: 'Prise de rendez-vous',
  Rédaction_du_devis_GC: 'Rédaction du devis GC',
  Réalisation_facture: 'Réalisation facture',
  Renseignement_de_la_date_prévisionnelle_des_travaux:
    'Renseignement de la date prévisionnelle des travaux',
  Facture: 'Facture',
  Vue_satellite: 'Vue satellite',
  Vue_toiture: 'Vue toiture',
  Tel: 'Tel',
  Rapport_de_visite_obligatoire: 'Rapport de visite obligatoire',
  Afficher_que_les_adresses_principales_et_l_addresse_du_batiment_etc:
    "Afficher que les adresses principales et l'adresse du bâtiment et autres adresses si disponibles dans les adresses de facturation",
  L_adresse_de_facturation_devis_correspond_a_l_adresse_du_batiment:
    "L'adresse de facturation (devis) correspond à l'adresse du bâtiment",
  Gestionnaire_de_factures: 'Gestionnaire de factures',
  ou: 'ou',
  Payé: 'Payé',
  Non_payé: 'Non payé',
  Avoir: 'Avoir',
  Nombre_total_de_factures: 'Nombre total de factures',
  Montant_total_des_factures_non_payées:
    'Montant total des factures non payées',
  Montant_total_des_factures_payées: 'Montant total des factures payées',
  Factures_non_payées_du_mois: 'Factures non payées du mois',
  Factures_payées_du_mois: 'Factures payées du mois',
  Chiffre_d_affaires_du_mois: "Chiffre d'affaires du mois",
  dont: 'dont',
  d_acompte: "d'acompte",
  veuillez_patienter: 'veuillez patienter',
  Prendre_rendez_vous: 'Prendre rendez vous',
  Cette_icone_indique_l_adresse_du_siege_social:
    "Cette icône indique l'adresse du siège social",
  Cette_icone_indique_l_adresse_du_batiment:
    "Cette icône indique l'adresse du bâtiment",
  Cette_icone_indique_le_proprietaire_actuel:
    'Cette icône indique le propriétaire actuel',
  Cette_icone_indique_le_locateur_actuel:
    'Cette icône indique le locateur actuel',
  Chiffre_d_affaire_cumulé: "Chiffre d'affaire cumulé",
  Se_deconnecter: 'Se déconnecter',
  Mon_compte: 'Mon compte',
  Gestionnaire_devis_factures: 'Gestionnaire devis / factures',
  Statistique_détaillée: 'Statistique détaillée',
  Notifications: 'Notifications',
  Date_de_validation: 'Date de validation',
  Urgence_infiltrations: 'Urgence infiltrations',
  interventions_sur_ce_batiment: 'interventions sur ce bâtiment',
  Centre_de_notifications: 'Centre de notifications',
  Effacer_tout: 'Effacer tout',
  Fermer: 'Fermer',
  Prospection_commerciale: 'Prospection commerciale',
  Aucune_agences_eligible_n_est_disponible:
    "Aucune agence éligible n'est disponible",
  Aucune_agences_eligible_AMIANTE_n_est_disponible:
    "Aucune agence éligible AMIANTE n'est disponible",
  contacter_votre_administrateur_agences_sous_traitants:
    'contacter votre administrateur agences / sous-traitants',
  de_ce_batiment: 'de ce bâtiment',
  le_batiment: 'le bâtiment',
  ce_batiment: 'ce bâtiment',
  Taux_de_marque: 'Taux de marque',
  et_ou: 'et/ou',
  un_client: 'un client',
  Taux_TVA_par_défaut: 'Taux TVA par défaut',
  Attention_ce_chiffre_correspondant_à_un_taux_de_marque_égal_à_zéro_etc:
    "Attention : ce chiffre correspondant à un taux de marque égal à zéro, cela ne veut pas dire que c'est le montant transmis par l'agence.",
  Créer_un_devis_agence: 'Créer un devis agence',
  Mise_a_jour_du_devis_agence: 'Mise à jour du devis agence',
  Créer_une_facture_agence: 'Créer une facture agence',
  Mise_à_jour_de_la_facture_agence: 'Mise à jour de la facture agence',
  Mise_à_jour_de_la_facture_gc: 'Mise à jour de la GC',
  Mise_a_jour_du_devis_gc: 'Mise à jour du devis GC',
  Créer_une_facture_gc: 'Créer une facture GC',
  Créer_un_acompte_gc: 'Créer un acompte GC',
  Éligibilité: 'Éligibilité',
  Factures_a_transmettre: 'Factures à transmettre',
  Les_statistiques_sont_automatiquement_actualisees_tous_les:
    'Les statistiques sont automatiquement actualisées tous les',
  Actualiser_les_statistiques: 'Actualiser les statistiques',
  le: 'le',
  Acompte: 'Acompte',
  Créer_un_rapport_de_visite_agence: 'Créer un rapport de visite agence',
  Mise_a_jour_du_rapport_de_visite_agence:
    'Mise à jour du rapport de visite agence',
  Créer_un_rapport_de_visite_gc: 'Créer un rapport de visite GC',
  Mise_a_jour_du_rapport_de_visite_gc: 'Mise à jour du rapport de visite GC',
  Chiffre_d_affaires_du_mois_en_cours: "Chiffre d'affaires du mois en cours",
  CA_du_mois_en_cours_CE: 'CA du mois en cours: CE',
  CA_du_mois_en_cours_Curatif: 'CA du mois en cours: Curatif',
  Chiffre_d_affaires_total: "Chiffre d'affaires total",
  Chargement_des_infos_et_statistiques_en_cours_etc:
    'Chargement des infos et statistiques en cours...',
  Aucun_contrat_d_entretien: "Aucun contrat d'entretien",
  facture_agence: 'facture agence',
  reglement_prevu: 'règlement prévu',
  Attila_grands_comptes: 'Attila grands comptes',
  référence: 'référence',
  Solde_payé_par_le_client: 'Solde payé par le client',
  Avec: 'Avec',
  Sans: 'Sans',
  Paiement_GC_franchise: 'Paiement GC➜franchise',
  d_un_montant_de: "d'un montant de",
  Acompte_prévu: 'Acompte prévu',
  Total_TTC: 'Total TTC',
  Total_HT: 'Total HT',
  Informations_complémentaire_interne: 'Informations complémentaire interne',
  Informations_supplementaire_sur_l_acompte:
    "Informations supplémentaire sur l'acompte",
  Facture_d_acompte: "Facture d'acompte",
  Paiement: 'Paiement',
  Commentaire: 'Commentaire',
  Date_de_règlement: 'Date de règlement',
  Facture_payée: 'Facture payée',
  cet_enregistrement_desacivera_les_relances_automatique_etc:
    'cet enregistrement désactivera les relances automatiquement, si vous souhaitez les réactiver ou rajouter des relances, vous pouvez toujours le faire par la suite.',
  Reste_a_payer_HT: 'Reste à payer HT',
  Reste_a_payer_HT_en_euro: 'Reste à payer HT (€)',
  Reste_a_payer_TTC: 'Reste à payer TTC',
  Référence_facture: 'Référence facture',
  Avoir_N: 'Avoir N°',
  Créer_une_facture_d_avoir: "Créer une facture d'avoir",
  Suivi_paiement: 'Suivi paiement',
  Payé_le: 'Payé le',
  par: 'par',
  Responsable_Grands_comptes: 'Responsable Grands-comptes',
  Commercial_e_Grands_Comptes: 'Commercial(e) Grands-Comptes',
  Assistant_e_commerciale_Grands_comptes:
    'Assistant(e) commerciale Grands-comptes',
  email_ou_mot_de_passe_incorrect: 'email ou mot de passe incorrect',
  Votre_session_a_expiré: 'Votre session a expiré',
  Important_Si_après_la_reconnexion: 'Important : Si, après la reconnexion',
  la_page_s_affiche_de_manière_incorrecte_ou_si_etc:
    "la page s'affiche de manière incorrecte ou si des données sont manquantes. Vous devez rafraîchir la page.",
  Voir_mes_evenement_uniquement: 'Voir mes événements uniquement',
  Responsable_GC: 'Responsable GC',
  pour_une_meilleur_experience_nous_vous_recommandons_d_utiliser_etc:
    "Pour une meilleure expérience, nous vous recommandons d'utiliser cette application sur un PC ou une tablette, cette application n'est pas adapté à une utilisation sur smartphone.",
  Afficher_toutes_les_relances: 'Afficher toutes les relances',
  Toutes_les_relances: 'Toutes les relances',
  Montant_agence_HT: 'Montant agence HT',
  Erreur_serveur: 'Erreur serveur',
  Tous_les_actions_non_terminées: 'Tous les actions non terminées',
  Afficher_toutes_les_actions: 'Afficher Toutes les actions',
  Note_seules_les_actions_a_faire_seront_afficher_ici:
    'Note: seules les actions à faire seront afficher ici',
  Paramètres: 'Paramètres',
  Administration: 'Administration',
  Agents_Attila: 'Agents Attila',
  Creer_un_nouveau_compte: 'Créer un nouveau compte',
  Confirmer_le_mot_de_passe: 'Confirmer le mot de passe',
  Mot_de_passe: 'Mot de passe',
  Admin: 'Admin',
  Assistant_e_commerciale: 'Assistant(e) commercial(e)',
  Super_Assistant_e_commerciale: 'Super Assistant(e) commercial(e)',
  Role: 'Rôle',
  Générer_un_mot_de_passe: 'Générer un mot de passe',
  Généré_et_copié_dans_le_presse_papier:
    'Généré et copié dans le presse-papiers',
  Les_mots_de_passes_que_vous_avez_saisis_ne_correspondent_pas:
    'Les mots de passes que vous avez saisis ne correspondent pas',
  Le_compte_a_été_créé_avec_succès: 'Le compte a été créé avec succès',
  Téléphone: 'Téléphone',
  Statistique: 'Statistique',
  Total_agents: 'Total agents',
  Modifier: 'Modifier',
  Modifier_mes_informations_personnelles:
    'Modifier mes informations personnelles',
  Récapitulatif: 'Récapitulatif',
  Chiffre_d_affaires: "Chiffre d'affaires",
  Commandes: 'Commandes',
  Clients_Agences: 'Clients/Agences',
  Importer_des_contacts: 'Importer des contacts',
  Contacts_importés_avec_succès_Veuillez_cliquer_sur_etc:
    'Contacts importés avec succès. Veuillez cliquer sur le bouton "ajouter" pour valider.',
  L_adresse_mail_est_manquante_sur_un_ou_plusieurs_contacts_l_email_est_important_etc:
    "L'adresse mail est manquante sur un ou plusieurs contacts, l'email est important pour la relance facture. Veuillez vérifier ça manuellement après validation.",
  desole_vous_n_avez_pas_l_autorisation_d_acceder_a_cette_page:
    "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
  Comptabilité: 'Comptabilité',
  Chargement: 'Chargement',
  Valider_la_facture_de_l_agence: "Valider la facture de l'agence",
  Rétablir_la_facture_de_l_agence: "Rétablir la facture de l'agence",
  Facture_agence_validée: 'Facture agence validée',
  Veuillez_sélectionner_une_adresse_de_facturation_pour_créer_un_libellé_de_facturation:
    'Veuillez sélectionner une adresse de facturation pour créer un libellé de facturation',
  désélectionner: 'désélectionner',
  Urgent: 'Urgent',
  sous_total_HT: 'sous-total HT',
  Validation_et_préparation_du_fichier_en_cours:
    'Validation et préparation du fichier en cours',
  Continuer_quand_même: 'Continuer quand même',
  Vos_derniers_changements_n_ont_pas_été_enregistrés_etc:
    "Vos derniers changements n'ont pas été enregistrés. Il faut sauvegarder avant de valider !",
  erreur_votre_demande_n_a_pas_pu_etre_traitee:
    "Erreur : votre demande n'a pas pu être traitée.",
  Réinitialiser_votre_mot_de_passe: 'Réinitialiser votre mot de passe',
  un_lien_pour_reinitialiser_votre_mot_de_passe_a_ete_envoye_par_email_etc:
    'Un lien pour réinitialiser votre mot de passe a été envoyé. \nSi vous ne recevez pas le mail dans quelques minutes, veuillez vérifier dans votre boîte SPAM.',
  Nouveau_mot_de_passe: 'Nouveau mot de passe',
  Confirmer_le_nouveau_mot_de_passe: 'Confirmer le nouveau mot de passe',
  veuillez_reverifier_le_lien_dans_votre_boite_mail:
    'Veuillez revérifier le lien dans votre boîte mail',
  Renvoyer_le_lien_de_réinitialisation: 'Renvoyer le lien de réinitialisation',
  Email_de_l_agence: "Email de l'agence",
  La_position_geo_n_a_pas_été_trouvée_vous_devez_le_faire_manuellement:
    "La position (géolocalisation) n'a pas été trouvée, vous devez le faire manuellement.",
  Recherche_de_la_geocalisation_en_cours:
    'Recherche de la géolocalisation en cours',
  géolocalisation: 'géolocalisation',
  Position_non_définie: 'Position non définie',
  dans: 'dans',
  l_edition_d_un_des_titre_est_encore_ouverte:
    "L'édition d'un des titres est encore ouverte",
  Veuillez_augmenter_le_rayon_de_recherche:
    'Veuillez augmenter le rayon de recherche',
  Limité_le_rayon_de_recherche_a: 'Limité le rayon de recherche à',
  KM: 'KM',
  Inclus_les_agences_non_géolocalisées: 'Inclus les agences non géolocalisées',
  Entrée_plan_comptable: 'Entrée plan comptable',
  Veuillez_choisir_l_agence: "Veuillez choisir l'agence",
  Délai_de_prise_de_contact: 'Délai de prise de contact',
  Délai_de_transmission_du_devis: 'Délai de transmission du devis',
  Délai_de_réalisation_des_travaux: 'Délai de réalisation des travaux',
  Délai_de_transmission_de_la_facture: 'Délai de transmission de la facture',
  Conforme: 'Conforme',
  Non_conforme: 'Non conforme',
  par_agence: 'par agence',
  Déverrouillé: 'Déverrouillé',
  montant_et_description: 'montant et description',
  Ajouter_directement_un_montant_et_une_description:
    'Ajouter directement un montant et une description',
  Une_facture_GC_a_déjà_été_générée: 'Une facture GC a déjà été générée',
  Un_rapport_de_visite_GC_a_déjà_été_générée:
    'Un rapport de visite GC a déjà été générée',
  Autorisé: 'Autorisé',
  Non_autorisé: 'Non autorisé',
  Statut: 'Statut',
  Paiement_facture_agence: 'Paiement facture agence',
  Motif: 'Motif',
  Dernier: 'Dernier',
  Reste_du_TTC: 'Reste dû TTC',
  Créé_par: 'Créé par',
  ce_client: 'ce client',
  Diagnostic: 'Diagnostic',
  Améliorations: 'Améliorations',
  Réparations: 'Réparations',
  Mesures_conservatoires: 'Mesures conservatoires',
  Plus: 'Plus',
  Un_ajustement_du_temps_de_travail_a_été_effectué:
    'Un ajustement du temps de travail a été effectué',
  Le_cout_horaire_envoyé_par_l_agence_ne_correspond_pas_a_la_valeur_etc:
    "Le coût horaire envoyé par l'agence ne correspond pas à la valeur par défaut pour ce type d'intervention. Un ajustement automatique a été effectué pour faire correspondre le montant agence HT au montant GC sans taux de marque",
  Ajouter_une_relance: 'Ajouter une relance',
  Methode: 'Méthode',
  Cible: 'Cible',
  Contenu: 'Contenu',
  Destinataire: 'Destinataire',
  annuler: 'annuler',
  la_commande: 'la commande',
  info_tarif: 'info tarif',
  SIRET_SIREN: 'SIRET / SIREN',
  Acompte_HT: 'Acompte HT',
  Gerer_ce_contrat_d_entretien: "Gérer ce contrat d'entretien",
  Refuser: 'Refuser',
  Refus_facture_agence: 'Refus facture agence',
  marquer_cette_intervention_comme_perdue:
    'marquer cette intervention comme perdue',
  Changer_le_statut: 'Changer le statut',
  Restauré: 'Restauré',
  Mise_a_jour_de_l_acompte_agence: "Mise à jour de l'acompte agence",
  interventions: 'interventions',
  Multipliable: 'Multipliable',
  Generer: 'Générer',
  GC: 'GC',
  un_devis: 'un devis',
  un_acompte: 'un acompte',
  une_facture: 'une facture',
  un_avoir: 'un avoir',
  Rejeter: 'Rejeter',
  Facture_annulée: 'Facture annulée',
  Facture_agence_refusée: 'Facture agence refusée',
  Une_erreur_est_survenue_lors_du_récupération:
    'Une erreur est survenue lors du récupération',
  de_l_image: "de l'image",
  du_fichier: 'du fichier',
  les_devis: 'les devis',
  les_factures: 'les factures',
  les_acomptes: 'les acomptes',
  les_avoirs: 'les avoirs',
  un_rapport_de_visite_GC: 'un rapport de visite GC',
  Montant_TTC: 'Montant TTC',
  Suivi_agence: 'Suivi agence',
  pays: 'pays',
  Assistante_gc_referente: 'Assistant(e) GC référent(e)',
  Pays: 'Pays',
  devis_agence: 'devis agence',
  Telephone_du_contact: 'Telephone du contact',
  Pays_du_client: 'Pays du client',
  Montant_HT_en_euro: 'Montant HT (€)',
  Temps_prévu_en_H: 'Temps prévu (H)',
  Acompte_HT_en_euro: 'Acompte HT (€)',
  Reste_du_HT_en_euro: 'Reste dû HT (€)',
  Reste_du_TTC_en_euro: 'Reste dû TTC (€)',
  Marge_en_pct: 'Marge (%)',
  Taux_de_marque_en_pct: 'Taux de marque (%)',
  Voir: 'Voir',
  actions_a_faire: 'actions à faire',
  Agents: 'Agents',
  desactiver: 'désactiver',
  Clients_désactivés: 'Clients désactivés',
  action: 'action',
  Délai_de_transmission_de_la_facture_detail_etc:
    'Délai: \n- 3 jours pour réaliser la facture liée à un devis hors CE \n- 15 jours pour réaliser la facture liée à un devis CE (conditionné par la transmission du rapport de visite',
  Délai_de_transmission_du_devis_detail_etc:
    'Délai:\n- 3 jours après le RDV pour un devis infiltration\n- 15 jours après le RDV pour un devis hors infiltration',
  Délai_de_la_prévision_du_début_des_travaux_detail_etc:
    'Délai:\n- 6 semaines entre la réception de la commande et la réalisation des travaux (DE uniquement)',
  Délai_de_prise_de_contact_detail_etc: 'Délai: \n- 24 heures',
  Liste: 'Liste',
  des_interventions_non_conformes: 'des interventions non conformes',
  Voir_la_liste: 'Voir la liste',
  Desactiver: 'Désactiver',
  Afficher_le_tableau_sur_toute_la_page:
    'Afficher le tableau sur toute la page',
  unitaire: 'unitaire',
  reinitialiser_la_facture_Toutes_les_modifications_etc:
    'réinitialiser la facture. Toutes les modifications en cours seront perdues',
  une_intervention: 'une intervention',
  Remise_appliquée: 'Remise appliquée',
  Facture_anticipée: 'Facture anticipée',
  Avant_remise: 'Avant remise',
  Après_remise: 'Après remise',
  Calcul_en_cours: 'Calcul en cours...',
  En_attente_de_validation: 'En attente de validation',
  Vous_etes_sur_le_point_de_creer_une_copie_du_PDF_validé_sans_passer_par_le_processus_habituel:
    'Vous êtes sur le point de créer une copie du PDF validé sans passer par le processus habituel.',
  Le_PDF_ainsi_généré_sera_toujours_identifiable:
    'Le PDF ainsi généré sera toujours identifiable !',
  Veuillez_cliquer_sur_le_bouton_valider_pour_continuer:
    'Veuillez cliquer sur le bouton "Valider" pour continuer.',
  Régénérer_une_copie_du_PDF_validé: 'Régénérer une copie du PDF validé',
  En_attente: 'En attente',
  Date_de_la_commande: 'Date de la commande',
  photos_par_page: 'photos par page',
  Cette_action_supprimera_ancien_PDF_et_ajoutera_le_nouveau:
    "Cette action supprimera l'ancien PDF et ajoutera le nouveau!",
  debut: 'début',
  fin: 'fin',
  Exporter: 'Exporter',
  tous: 'tous',
  toutes: 'toutes',
  Rouge: 'Rouge',
  Orange: 'Orange',
  Gris: 'Gris',
  Inactive: 'Inactive',
  les: 'les',
  extraction: 'extraction',
  factures_impayées: 'factures impayées',
  Date_de_réalisation_des_travaux: 'Date de réalisation des travaux',
  Réalisation_des_travaux: 'Réalisation des travaux',
  un: 'un',
  une: 'une',
  facture_gc: 'facture gc',
  Mise_a_jour: 'Mise à jour',
  Creer: 'Créer',
  devis_gc: 'devis gc',
  Survenu_le: 'Survenu le',
  Agent_GC: 'Agent GC',
  Autorisation_de_paiement_du_dépôt_de_l_agence:
    'Autorisation de paiement - acompte agence',
  avoir_gc: 'avoir gc',
  acompte_gc: 'acompte gc',
  rapport_de_visite_gc: 'rapport de visite gc',
  Autoriser: 'Autoriser',
  paiement_agence: 'paiement agence',
  statistiques: 'statistiques',
  acompte_agence: 'acompte agence',
  Assigner: 'Assigner',
  a_une_agence: 'à une agence',
  Marquer: 'Marquer',
  comme_perdue: 'comme perdue',
  Mettre_a_jour: 'mettre à jour',
  des_fichiers_a_une_intervention: 'des fichiers à une intervention',
  Paiement_client: 'Paiement client',
  rapport_de_visite_agence: 'rapport_de_visite_agence',
  des_fichiers_d_une_intervention: "des fichiers d'une intervention",
  des_fichiers_a_un_SAV: 'des fichiers à un SAV',
  des_fichiers_d_un_SAV: "des fichiers d'un SAV",
  Acompte_annulé: 'Acompte annulé',
  Montant_TVA_en_euro: 'Montant TVA (€)',
  Montant_TTC_en_euro: 'Montant TTC (€)',
  N_commande: 'N° commande',
  Divers: 'Divers',
  Paiement_facture_GC: 'Paiement facture GC',
  Paiement_prevue_facture_GC: 'Paiement prévue facture GC',
  Etat_facture_GC: 'Etat facture GC',
  l_adresse_mail: "l'adresse mail",
  factures_acomptes_avoirs: 'factures / acomptes / avoirs',
  Photos_probleme: 'Photos problème',
  Photos_solution: 'Photos solution',
  Commercial_responsable: 'Commercial responsable',
  Agent_Attila: 'Agent Attila',
  prevue: 'prévue',
  Validation_de_la_facture_agence: 'Validation de la facture agence',
  par_defaut: 'par défaut',
  Autres_adresses_de_facturation: 'Autres adresses de facturation',
  Filtrer_les_adresses: 'Filtrer les adresses',
  Montrer_plus_d_adresses: "Montrer plus d'adresses",
  Afficher_en_plus_les_adresses_de_facturation:
    'Afficher en plus les adresses de facturation',
  Recherche_par: 'Recherche par',
  agence_la_plus_proche: 'agence la plus proche',
  zone_attribuée: 'zone attribuée',
  switcher: 'switcher',
  ou_passer_à_la_recherche_de_l_agence_la_plus_proche:
    "ou passer à la recherche de l'agence la plus proche",
  utiliser_le_buton_switcher_en_haut: "utiliser le buton 'switcher' en haut",
  Veuillez: 'Veuillez',
  Aucune_agence_disponible_selon_le_critere_ZONE_ATTRIBUEE_les_agences_etc:
    'Aucune agence disponible selon le critère "ZONE ATTRIBUÉE", les agences sont classées par ordre de distance croissante',
  Switcher_vers: 'Switcher vers',
  Commande: 'Commande',
  Un_ou_plusieurs_interventions_n_ont_pas_de_devis_validés:
    "Un ou plusieurs interventions, n'ont pas de devis validés.",
  Interventions_selectionnees: 'Interventions sélectionnées',
  Veuillez_selectionner_les_references: 'Veuillez sélectionner les références',
  References_selectionees: 'Références sélectionnées',
  Selectionner_tous: 'Sélectionner tous',
  Deselectionner_tous: 'Désélectionner tous',
  édition: 'édition',
  Le_cachet_est_probablement_manquant: 'Le cachet est probablement manquant',
  le_pourcentage_de_non_presence: 'le pourcentage de non-présence',
  Il_est_necessaire_de_géolocaliser_le_batiment_au_prealable:
    'Il est nécessaire de géolocaliser le bâtiment au préalable.',
  Attribuer_l_intervention_a_une_agence:
    "Attribuer l'intervention à une agence",
  Vous_pouvez_effectuer_cette_action_dans_historique_du_batiment_batiment:
    'Vous pouvez effectuer cette action dans: historique du bâtiment > bâtiment',
  Typage: 'Typage',
  validées: 'validées',
  Periode: 'Période',
  clients: 'clients',
  Indicateurs_performance: 'Indicateurs performance',
  National: 'National',
  Mode_de_réception: 'Choisissez le mode de réception du fichier',
  Excel:
    'La création immédiate du fichier est désactivée en raison du grand nombre de lignes dans le tableau de données. Merci de votre compréhension',
  Mail: 'Par courriel',
  Immediate: 'Immédiatement',
  Twimm_Key: 'Clé application TWIMM',
  List_sites: 'Liste des sites',
  Site_Placeholder: 'site',
  Pdf: 'pdf',
  Twimm: 'Twimm',
  Sync_Twimm: 'Synchronisation Twimm',
  Synchronisation_en_cours: 'Synchronisation en cours',
  Synchronisation_success: 'Synchronisation a été faite avec succès',
  Montant_HT_en_euro_Agence: 'Montant HT devis Agence (€)',
  Montant_HT_en_euro_Gc: 'Montant HT devis GC (€)',
  Sans_Date_Paiement_Prévu: 'Sans date paiement prévu',
  De: 'De',
  Vers: 'Vers',
  Adresse_Agence: "Adresse de l'agence",
  Changer_Direction:
    'Vous pouvez changer la direction en cliquant sur ce bouton',
  Attention_factoring_semble_etre_present_dans_la_description:
    "Attention : le champ de description contient le mot 'affacturage' ou un terme similaire",
  Avertissement_factoring:
    "Pour être libératoire, votre règlement doit être effectué directement à l'ordre de Crédit Agricole Leasing & Factoring – 12 place des Etats-Unis – CS 20001 – 92 548 Montrouge Cedex – France, qui le reçoit par subrogation dans le cadre d'un contrat d'affacturage et devra être avisé de toute réclamation relative à cette créance.",
  Veuillez_sélectionner_une_adresse_de_facturation_pour_ajouter_un_commentaire_de_facturation:
    'Veuillez sélectionner une adresse de facturation pour ajouter un commentaire de facturation',
  Recouvrement: 'Recouvrement',
  Intervention_est_en_cours: 'Intervention est déjà en cours',
  Numero_Intervention_TWIMM: 'Numéro intervention chez TWIMM',
  TWIMM_N: 'TWIMM N°'
};
