import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner/LoadingSpinner';
import { ACCESS_DENIED, LOGIN } from '~/constants/internalLinks';
import {
  isAuthenticatedSelector,
  isAuthLoadingSelector,
  isUserMeLoadingSelector,
  userRoleSelector,
  userHasAccessTokenSelector
} from '~/redux/features/user/userAuthSlice';
import { useAppSelector } from '~/redux/redux-hooks';
import { RolesRouteProps } from '../Routes.types';

const RolesRoute = ({ roles, children, ...rest }: RolesRouteProps) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const userRole = useAppSelector(userRoleSelector);
  const isAuthLoading = useAppSelector(isAuthLoadingSelector);
  const isUserMeLoading = useAppSelector(isUserMeLoadingSelector);
  const userHasAccessToken = useAppSelector(userHasAccessTokenSelector);

  const hasRole = userRole && roles.includes(userRole);

  if (isAuthenticated && hasRole) return <Route {...rest}>{children}</Route>;
  if (isAuthenticated && !hasRole) return <Redirect to={ACCESS_DENIED} />;
  return (
    <>
      {!userHasAccessToken && !isAuthLoading && !isUserMeLoading && (
        <Redirect to={{ pathname: LOGIN, state: { from: location } }} />
      )}
      <LoadingSpinner />
    </>
  );
};

export default RolesRoute;
