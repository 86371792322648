/* eslint-disable dot-notation */
import axios, { InternalAxiosRequestConfig } from 'axios';
import { ToastError } from '~/components/Toast/ToastError';
import { ACCESS_TOKEN_EXPIRED } from '~/constants/auth';
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '~/constants/storageKeys';
import { eventBus } from '~/utils/shared';
import { getNewAccessTokenWithRefreshToken } from './auth';

export const axiosPrivate = axios.create();

axiosPrivate.interceptors.response.use(undefined, async (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  const isCanceledError =
    error?.message === 'canceled' ||
    error?.name === 'CanceledError' ||
    error?.code === 'ERR_CANCELED';

  if (!expectedError && !axios.isCancel(error) && !isCanceledError) {
    ToastError(error);
  }
  const prevRequest = error?.config;
  // why prevRequest?. to try only once (otherwise we end-up in an endless loop)
  if (error?.response?.status === 401 && !prevRequest?.sent) {
    // TODO: ask guys to add message like'jwt expired' in the api error msg
    prevRequest.sent = true;
    try {
      const res = await getNewAccessTokenWithRefreshToken();
      if (res?.access_token) {
        localStorage.setItem(TOKEN_KEY, res.access_token);
        prevRequest.headers['Authorization'] = `Bearer ${res.access_token}`;
        if (res?.refresh_token) {
          localStorage.setItem(REFRESH_TOKEN_KEY, res.refresh_token);
        }
        return axiosPrivate(prevRequest);
      }
    } catch (ex) {
      // if error, when trying to get the new accessToken from
      // the refresh token, we ask the user to re-login
      eventBus.dispatch(ACCESS_TOKEN_EXPIRED);
    }
  }

  return Promise.reject(error);
});

axiosPrivate.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axiosPrivate.get,
  post: axiosPrivate.post,
  put: axiosPrivate.put,
  patch: axiosPrivate.patch,
  delete: axiosPrivate.delete
};
