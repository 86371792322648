import {
  DIRECTORY_USERS,
  DIRECTORY_ACCOUNTS,
  CUSTOMERS,
  REPORT
} from '~/constants/api';
import useFetch from '~/hooks/useFetch';
import { PagedResultDtoOfDisplayCustomerDto } from './api.types';
import {
  DisplayUserDtoPagedResultDto,
  DisplayUserDto,
  CreateAccountDto
} from './directoryApi.types';
import http from './httpService';
import { Order } from '~/components/TableComponent/TableComponent.types';
import { cleanObject } from '~/utils/objectUtils';

export async function getGcAgents(): Promise<DisplayUserDtoPagedResultDto> {
  const { data } = await http.get(`${DIRECTORY_USERS}`);
  return data;
}

export async function createGcAgent(
  val: CreateAccountDto
): Promise<DisplayUserDto> {
  const { data } = await http.post(`${DIRECTORY_ACCOUNTS}`, val);
  return data;
}

interface GcAgentsFindResponse {
  gcUsersData: DisplayUserDtoPagedResultDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchGcAgents = (
  hasToRefetch: boolean
): GcAgentsFindResponse => {
  const {
    data: gcUsersData,
    isLoading,
    error
  } = useFetch(DIRECTORY_USERS, true, hasToRefetch);
  return { gcUsersData, isLoading, error };
};

interface DeactivatedCustomersFindResponse {
  data: PagedResultDtoOfDisplayCustomerDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchDeactivatedCustomers = (
  rowsPerPage: number,
  rowToSkip: number,
  searchQuery: object,
  order: Order
): DeactivatedCustomersFindResponse => {
  const cleanedObject = cleanObject(searchQuery);
  // @ts-ignore
  const qsParams = new URLSearchParams(cleanedObject).toString();
  const url = searchQuery
    ? `${CUSTOMERS}/disabled?Take=${rowsPerPage}&Skip=${rowToSkip}&${qsParams}&OrderBy.FullName=${order}`
    : `${CUSTOMERS}/disabled?Take=${rowsPerPage}&Skip=${rowToSkip}&OrderBy.FullName=${order}`;
  const { data, isLoading, error } = useFetch(url);

  return { data, isLoading, error };
};

export const reactivateCustomer = async (id: number): Promise<number> => {
  const { data } = await http.patch(`${CUSTOMERS}/${id}/enable`);
  return data;
};

// TODO
// eslint-disable-next-line unused-imports/no-unused-vars
export const deactivateAgent = async (id: number) => {
  // console.log('submitting', id);
};

export const updateAgent = async (id: number, val: any) => {
  const { data } = await http.patch(`${DIRECTORY_ACCOUNTS}/${id}`, val);
  return data;
};

export async function syncTwimmInterventions() {
  await http.get(`${REPORT}/sync-twimm`);
}
