import { format } from 'date-fns';
import {
  CreateCommentDto,
  DateWithMessage,
  PagedResultDtoOfDisplayEligibleAgencyDto,
  PagedResultDtoOfDisplayAgencyWithLegalDocumentsDto,
  UpdateAgencyEligibiltyDto,
  LegalDocument,
  DisplayCommentDto,
  DisplayRevenuesDto,
  DisplayAgencyWithLegalDocumentsDto,
  CreateAgencyEmailDto,
  CreateAgencyGeoLocationDto,
  DisplayAgencyPerformanceDto,
  PerformanceIndicator,
  PagedResultDtoOfDisplayAgencyPerformanceDetailsDto,
  DisplayAgencyDto
} from './api.types';
import http from './httpService';
import { AGENCIES, REPORT } from '~/constants/api';
import useFetch from '~/hooks/useFetch';

interface AgenciesFindResponse {
  data: PagedResultDtoOfDisplayAgencyWithLegalDocumentsDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchAgencies = (): AgenciesFindResponse => {
  const { data, isLoading, error } = useFetch(
    `${AGENCIES}?IncludeLegalDocument=true`
  );
  return { data, isLoading, error };
};

export async function agencyAddComment(
  id: number,
  val: CreateCommentDto
): Promise<DisplayCommentDto> {
  const { data } = await http.post(`${AGENCIES}/${id}/comment`, val);
  return data;
}

export async function agencyAddVisitDate(
  id: number,
  val: { date: Date; message: string }
): Promise<DateWithMessage> {
  const { data } = await http.post(`${AGENCIES}/${id}/visit-date`, val);
  return data;
}

export async function agencyChangeEligibility(
  id: number,
  val: UpdateAgencyEligibiltyDto
): Promise<number> {
  const { data } = await http.patch(`${AGENCIES}/${id}/eligibility`, val);
  return data;
}

export async function agencyUpdateFormData(
  endpoint: string,
  formData: FormData
): Promise<LegalDocument> {
  const { data } = await http.put(endpoint, formData);
  return data;
}

export async function agencyUpdateDateOnly(
  endpoint: string,
  date: Date | null
): Promise<LegalDocument> {
  const { data } = await http.put(endpoint, {
    date
  });
  return data;
}

export async function agencyUpdateEmail(
  id: number,
  val: CreateAgencyEmailDto
): Promise<number> {
  const { data } = await http.put(`${AGENCIES}/${id}/email`, val);
  return data;
}

export async function agencyUpdateGeoLocation(
  id: number,
  val: CreateAgencyGeoLocationDto
): Promise<number> {
  const { data } = await http.put(`${AGENCIES}/${id}/geo-location`, val);
  return data;
}

export async function agencyDeleteFile(
  endpoint: string,
  fileId: string
): Promise<number> {
  const { data } = await http.delete(`${endpoint}/${fileId}`);
  return data;
}

export async function getEligibleAgencies(): Promise<PagedResultDtoOfDisplayEligibleAgencyDto> {
  const { data } = await http.get(`${AGENCIES}/eligible`);
  return data;
}

export interface PagedResultDtoOfDisplayAgencyDto {
  values?: DisplayAgencyDto[] | null;
  count?: number;
}

export async function getAgenciesWithoutLegalDocuments(): Promise<PagedResultDtoOfDisplayAgencyDto> {
  const { data } = await http.get(`${AGENCIES}?IncludeLegalDocument=false`);
  return data;
}

interface AgencyRevenuesResponse {
  agencyRevenues: DisplayRevenuesDto | null;
  isLoadingAgencyRevenues: boolean;
  agencyRevenuesError: string | null;
}

export const useFetchAgencyRevenues = (id: number): AgencyRevenuesResponse => {
  const {
    data: agencyRevenues,
    isLoading: isLoadingAgencyRevenues,
    error: agencyRevenuesError
  } = useFetch(`${AGENCIES}/${id}/revenues`);
  return { agencyRevenues, isLoadingAgencyRevenues, agencyRevenuesError };
};

interface AgencyFindResponse {
  agencyData: DisplayAgencyWithLegalDocumentsDto | null;
  isLoadingAgencyData: boolean;
  agencyDataError: string | null;
}

export const useFetchAgencyById = (id: number): AgencyFindResponse => {
  const {
    data: agencyData,
    isLoading: isLoadingAgencyData,
    error: agencyDataError
  } = useFetch(`${AGENCIES}/${id}?IncludeLegalDocument=false`);
  return {
    agencyData,
    isLoadingAgencyData,
    agencyDataError
  };
};

interface AgencyPerformancesResponse {
  dataAgenciesPerformance: DisplayAgencyPerformanceDto | null;
  isLoadingAgenciesPerformance: boolean;
  errorAgenciesPerformance: string | null;
}

export const useFetchAgencyPerformances = (
  id: number | null,
  startDate: Date | null,
  endDate: Date | null
): AgencyPerformancesResponse => {
  let url = `${AGENCIES}/${id}/performance?`;
  if (startDate) {
    const start = format(startDate, 'yyyy-MM-dd');
    url += `date.From=${start}&`;
  }
  if (endDate) {
    const end = format(endDate, 'yyyy-MM-dd');
    url += `date.To=${end}`;
  }
  const {
    data: dataAgenciesPerformance,
    isLoading: isLoadingAgenciesPerformance,
    error: errorAgenciesPerformance
  } = useFetch(url, !!id);
  return {
    dataAgenciesPerformance,
    isLoadingAgenciesPerformance,
    errorAgenciesPerformance
  };
};

interface NationalPerformancesResponse {
  dataNationalPerformances: DisplayAgencyPerformanceDto | null;
  isLoadingNationalPerformances: boolean;
  errorNationalPerformances: string | null;
}

export const useFetchTotalNationalPerformances = (
  id: number | null,
  startDate: Date | null,
  endDate: Date | null
): NationalPerformancesResponse => {
  let url = `${AGENCIES}/performance?`;
  if (startDate) {
    const start = format(startDate, 'yyyy-MM-dd');
    url += `date.From=${start}&`;
  }
  if (endDate) {
    const end = format(endDate, 'yyyy-MM-dd');
    url += `date.To=${end}`;
  }
  const {
    data: dataNationalPerformances,
    isLoading: isLoadingNationalPerformances,
    error: errorNationalPerformances
  } = useFetch(url, !id);
  return {
    dataNationalPerformances,
    isLoadingNationalPerformances,
    errorNationalPerformances
  };
};

interface AgenciesNonCompliantInterventionsResponse {
  data: PagedResultDtoOfDisplayAgencyPerformanceDetailsDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchNonCompliantInterventions = (
  id: number | null,
  performanceIndicator: PerformanceIndicator,
  rowsPerPage: number,
  rowToSkip: number,
  startDate: Date | null,
  endDate: Date | null
): AgenciesNonCompliantInterventionsResponse => {
  let url = id
    ? `${AGENCIES}/${id}/performance-details?PerformanceIndicator=${performanceIndicator}&Take=${rowsPerPage}&Skip=${rowToSkip}`
    : `${AGENCIES}/performance-details?PerformanceIndicator=${performanceIndicator}&Take=${rowsPerPage}&Skip=${rowToSkip}`;
  if (startDate) {
    const start = format(startDate, 'yyyy-MM-dd');
    url += `&date.From=${start}`;
  }
  if (endDate) {
    const end = format(endDate, 'yyyy-MM-dd');
    url += `&date.To=${end}`;
  }
  const { data, isLoading, error } = useFetch(url);

  return { data, isLoading, error };
};

export const deleteAgencyEmail = async (agencyId: number) => {
  const { data } = await http.delete(`${AGENCIES}/${agencyId}/email`);
  return data;
};
export async function ExportExcelReport() {
  await http.get(`${REPORT}/send-agencies`);
}
export const getAgencyPerformances = async (
  id: number | null,
  startDate: Date | null,
  endDate: Date | null
): Promise<DisplayAgencyPerformanceDto> => {
  let url = `${AGENCIES}/${id}/performance?`;
  if (startDate) {
    const start = format(startDate, 'yyyy-MM-dd');
    url += `date.From=${start}&`;
  }
  if (endDate) {
    const end = format(endDate, 'yyyy-MM-dd');
    url += `date.To=${end}`;
  }
  const { data } = await http.get(url);
  return data;
};
