import toast from 'react-hot-toast';
import { defaultColor } from '~/constants/colors';
import { Error } from './ToastError.types';

export const ToastError = (err: Error) => {
  const errMsg =
    err.response?.data?.message ||
    err.response?.data?.title ||
    err?.message ||
    err;
  return toast.error(
    `Error ${err?.response?.status || err.response?.data?.StatusCode}. ${
      errMsg || ''
    }`,
    {
      style: {
        padding: '1rem',
        color: defaultColor.white,
        backgroundColor: '#f44336',
        minWidth: '400px'
      },
      duration: 4000
    }
  );
};
