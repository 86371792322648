export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const HOME = '/';
export const LOGOUT = '/logout';
export const CUSTOMER_RECORD = '/customer-record';
export const BUILDING = '/building';
export const AGENCIES_ADMINISTRATION = '/agencies-administration';
export const INTERVENTIONS = '/interventions';
export const NOT_FOUND_404 = '/404-not-found';
export const ALL_INTERVENTIONS = '/all-interventions';
export const VISIT_REPORT = '/visit-report';
export const BILLING = '/billing';
export const QUOTES_MANAGER = '/quotes-manager';
export const INVOICES_MANAGER = '/invoices-manager';
export const ALL_TODOS = '/all-todos';
export const ADMINISTRATION = '/administration';
export const ADMINISTRATION_AGENTS = '/administration/attila-agents';
export const ADMINISTRATION_DEACTIVATED_CUSTOMERS =
  '/administration/deactivated-customers';
export const MY_ACCOUNT = '/my-account';
export const STATS = '/stats';
export const STATS_TURNOVER = '/stats/turnover';
export const STATS_QUOTES = '/stats/quotes';
export const STATS_ORDER = '/stats/order';
export const STATS_CUSTOMERS_AND_AGENCIES = '/stats/customers-agencies';
export const STATS_GC_AGENTS = '/stats/gc-agents';
export const STATS_RECAP = '/stats/recap';
export const ACCESS_DENIED = '/acess-denied';
export const TWIMM = '/administration/twimm';
