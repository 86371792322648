import { InterventionTaskType } from '~/services/api.types';
import { strings } from './strings';

export const actionTypes = [
  {
    value: InterventionTaskType.CreateContactInstance,
    name: strings.Prise_de_contact
  },
  {
    value: InterventionTaskType.CreateAppointment,
    name: strings.Prendre_rendez_vous
  },
  {
    value: InterventionTaskType.CreateGcQuote,
    name: strings.Rédaction_du_devis_GC
  },
  {
    value: InterventionTaskType.AddWorkStartDate,
    name: strings.Renseignement_de_la_date_prévisionnelle_des_travaux
  },
  {
    value: InterventionTaskType.CreateGcInvoice,
    name: `${strings.Facture} - ${strings.Créer_une_facture_gc}`
  },
  {
    value: InterventionTaskType.QuotePhoneReminderAgency,
    name: `${strings.Devis} - ${strings.Agence} - ${strings.relance_par_téléphone} `
  },
  {
    value: InterventionTaskType.QuoteMailReminderAgency,
    name: `${strings.Devis} - ${strings.Agence} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.QuotePhoneReminderCustomer,
    name: `${strings.Devis} - ${strings.Client} - ${strings.relance_par_téléphone} `
  },
  {
    value: InterventionTaskType.QuoteMailReminderCustomer,
    name: `${strings.Devis} - ${strings.Client} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.VisitReportPhoneReminderAgency,
    name: `${strings.Rapport_de_visite} - ${strings.Agence} - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.VisitReportMailReminderAgency,
    name: `${strings.Rapport_de_visite} - ${strings.Agence} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.VisitReportPhoneReminderCustomer,
    name: `${strings.Rapport_de_visite} - ${strings.Client} - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.VisitReportMailReminderCustomer,
    name: `${strings.Rapport_de_visite} - ${strings.Client} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.ReceptionRecordPhoneReminderAgency,
    name: `${strings.PV_réception} - ${strings.Agence} - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.ReceptionRecordMailReminderAgency,
    name: `${strings.PV_réception} - ${strings.Agence} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.ReceptionRecordPhoneReminderCustomer,
    name: `${strings.PV_réception} - ${strings.Client} - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.ReceptionRecordMailReminderCustomer,
    name: `${strings.PV_réception} - ${strings.Client} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.PreventionFilePhoneReminderAgency,
    name: `${strings.plan_prévention} - ${strings.Agence}  - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.PreventionFileMailReminderAgency,
    name: `${strings.plan_prévention} - ${strings.Agence}  - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.PreventionFilePhoneReminderCustomer,
    name: `${strings.plan_prévention} - ${strings.Client}  - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.PreventionFileMailReminderCustomer,
    name: `${strings.plan_prévention} - ${strings.Client}  - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.SavPhoneReminderAgency,
    name: `${strings.SAV} - ${strings.Agence}  - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.SavMailReminderAgency,
    name: `${strings.SAV} - ${strings.Agence}  - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.SavPhoneReminderCustomer,
    name: `${strings.SAV} - ${strings.Client}  - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.SavMailReminderCustomer,
    name: `${strings.SAV} - ${strings.Client}  - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.InvoicePhoneReminderAgency,
    name: `${strings.Facture} - ${strings.Agence} - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.InvoiceMailReminderAgency,
    name: `${strings.Facture} - ${strings.Agence} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.InvoicePhoneReminderCustomer,
    name: `${strings.Facture} - ${strings.Client}  - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.InvoiceMailReminderCustomer,
    name: `${strings.Facture} - ${strings.Client}  - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.CreateAgencyQuote,
    name: `${strings.Devis} - ${strings.Créer_un_devis_agence}`
  },
  {
    value: InterventionTaskType.UpdateAgencyQuote,
    name: `${strings.Devis} - ${strings.Mise_a_jour_du_devis_agence}`
  },
  {
    value: InterventionTaskType.CreateAgencyInvoice,
    name: `${strings.Facture} - ${strings.Créer_une_facture_agence}`
  },
  {
    value: InterventionTaskType.UpdateAgencyInvoice,
    name: `${strings.Facture} - ${strings.Mise_à_jour_de_la_facture_agence}`
  },
  {
    value: InterventionTaskType.UpdateGcInvoice,
    name: `${strings.Facture} - ${strings.Mise_à_jour_de_la_facture_gc}`
  },
  {
    value: InterventionTaskType.UpdateGcQuote,
    name: `${strings.Devis} - ${strings.Mise_a_jour_du_devis_gc}`
  },
  {
    value: InterventionTaskType.CreateAgencyVisitReport,
    name: `${strings.Rapport_de_visite} - ${strings.Créer_un_rapport_de_visite_agence}`
  },
  {
    value: InterventionTaskType.UpdateAgencyVisitReport,
    name: `${strings.Rapport_de_visite} - ${strings.Mise_a_jour_du_rapport_de_visite_agence}`
  },
  {
    value: InterventionTaskType.CreateGcVisitReport,
    name: `${strings.Rapport_de_visite} - ${strings.Créer_un_rapport_de_visite_gc}`
  },
  {
    value: InterventionTaskType.UpdatedGcVisitReport,
    name: `${strings.Rapport_de_visite} - ${strings.Mise_a_jour_du_rapport_de_visite_gc}`
  },
  {
    value: InterventionTaskType.DepositPhoneReminderCustomer,
    name: `${strings.Acompte} - ${strings.Client} - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.DepositMailReminderCustomer,
    name: `${strings.Acompte}- ${strings.Client} - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.DepositPhoneReminderAgency,
    name: `${strings.Acompte}- ${strings.Agence} - ${strings.relance_par_téléphone}`
  },
  {
    value: InterventionTaskType.DepositMailReminderAgency,
    name: `${strings.Acompte}- ${strings.Agence}  - ${strings.relance_par_mail}`
  },
  {
    value: InterventionTaskType.UpdateAgencyDeposit,
    name: `${strings.Acompte}- ${strings.Mise_a_jour_de_l_acompte_agence}`
  },
  {
    value: InterventionTaskType.CreateGcDeposit,
    name: `${strings.Facture} - ${strings.Créer_un_acompte_gc}`
  }
];

export const filterBoxTodoTypesTableHeaderData = [
  {
    code: strings.Facture,
    title: strings.Facture
  },
  {
    code: strings.Devis,
    title: strings.Devis
  },
  {
    code: strings.Acompte,
    title: strings.Acompte
  },
  {
    code: strings.SAV,
    title: strings.SAV
  },
  {
    code: strings.Rapport_de_visite,
    title: strings.Rapport_de_visite
  },
  {
    code: strings.PV_réception,
    title: strings.PV_réception
  },
  {
    code: strings.plan_prévention,
    title: strings.plan_prévention
  },
  {
    code: 'autre',
    title: ''
  }
];

export const allTodosTableHeaderData = [
  { code: 'fullName', title: strings.client },
  { code: 'type', title: strings.Type },
  { code: 'reference', title: strings.Reference },
  { code: 'creationDate', title: strings.Date_de_création },
  { code: 'dueDate', title: strings.date_limite },
  { code: 'user', title: strings.Utilisateur },
  { code: 'comment', title: strings.Commentaire }
];
