import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  PagedResultDtoOfDisplayAgencyDto,
  getAgenciesWithoutLegalDocuments
} from '~/services/agencies';
import { RootState } from '../../store';

interface State {
  agencies: PagedResultDtoOfDisplayAgencyDto | null;
  isLoading: boolean;
  hasError: boolean;
}

const initialState: State = {
  agencies: null,
  isLoading: false,
  hasError: false
};

export const fetchAgencies = createAsyncThunk(
  'agencies/fetchAgencies',
  async () => {
    const agencies = await getAgenciesWithoutLegalDocuments();
    return agencies;
  }
);

const agenciesSlice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAgencies.fulfilled, (state, action) => {
      state.agencies = action.payload;
      state.isLoading = false;
      state.hasError = false;
    });
    builder.addCase(fetchAgencies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAgencies.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
    });
  }
});

// we use create selector to get cached fast response (it like reselect)
export const agenciesSelector = (state: RootState) => state.agencies.agencies;
export const agenciesLoaderSelector = (state: RootState) =>
  state.agencies.isLoading;
export const agenciesFetchingHasError = (state: RootState) =>
  state.agencies.hasError;

export default agenciesSlice.reducer;
