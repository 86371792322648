import { strings } from './strings';

export const companyStaffSizeRanges = [
  { value: 0, title: strings.N_D },
  { value: 1, title: '1 ou 2' },
  { value: 2, title: '3 à 5' },
  { value: 3, title: '6 à 9' },
  { value: 11, title: '10 à 19' },
  { value: 12, title: '20 à 49' },
  { value: 21, title: '50 à 99' },
  { value: 22, title: '100 à 199' },
  { value: 31, title: '200 à 249' },
  { value: 32, title: '250 à 499' },
  { value: 41, title: '500 à 999' },
  { value: 42, title: '1000 à 1 999' },
  { value: 51, title: '2000 à 4 999' },
  { value: 52, title: '5000 à 9 999' },
  { value: 53, title: '+ de 10 000' }
];

export const turnoverRanges = [
  { value: -1, title: strings.N_D },
  { value: 0, title: "Moins de 0,5 million d'euros" },
  { value: 1, title: "De 0,5 à moins de 1 million d'euros" },
  { value: 2, title: "De 1 million à moins de 2 millions d'euros" },
  { value: 3, title: "De 2 millions à moins de 5 millions d'euros" },
  { value: 4, title: "De 5 millions à moins de 10 millions d'euros" },
  { value: 5, title: "De 10 millions à moins de 20 millions d'euros" },
  { value: 6, title: "De 20 millions à moins de 50 millions d'euros" },
  { value: 7, title: "De 50 millions à moins de 100 millions d'euros" },
  { value: 8, title: "De 100 millions à moins de 200 millions d'euros" },
  { value: 9, title: "200 millions d'euros ou plus" }
];

export const commercialProspectingTableHeaderData = [
  { code: 'attribution', title: strings.Attribution },
  { code: 'name', title: strings.Nom },
  { code: 'active', title: strings.Actif },
  { code: 'NAF', title: strings.Code_NAF },
  { code: 'NAF_label', title: strings.Intitulé_de_la_NAF },
  { code: 'head_count_slice', title: strings.Effectifs },
  { code: 'revenue_slice ', title: strings.Chiffre_d_affaire },
  { code: 'creation_date_ts', title: strings.Date_de_création },
  { code: 'SIREN', title: strings.SIREN },
  { code: 'status_group_label', title: strings.Status_du_goupe },
  { code: 'street', title: strings.Adresse },
  { code: 'city', title: strings.Ville },
  { code: 'zip', title: strings.Code_postal },
  { code: 'website', title: strings.Site_web }
];

export const googleSearchUrl = 'https://www.google.fr/search?q=';
