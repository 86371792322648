/* eslint-disable no-else-return */
import { nanoid } from 'nanoid';
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  differenceInSeconds
} from 'date-fns';
import { customAlphabet } from 'nanoid/non-secure';
import { arrayToObject } from './arrayUtils';
import {
  allowedImgType,
  allowedImgExtension,
  allowedDocType,
  allowedDocExtension
} from '~/constants/files';
import { strings } from '~/constants/strings';
import { TEMPORARY_ID } from '~/constants/shared';
import { Roles } from '~/redux/features/user/userAuthSlice';
import { countries, defaultCountry } from '~/constants/countries';
import {
  reminderTarget,
  reminderTypes,
  reminderMethods
} from '~/constants/reminder';
import { actionTypes } from '~/constants/actions';
import {
  quoteUnitTypes,
  invoicePaymentAuthorizationTypes
} from '~/constants/billing';
import { quoteTypes } from '~/constants/interventions';
import {
  communicationChannelTypes,
  salesEventTypes
} from '~/constants/calendar';
import {
  companyStaffSizeRanges,
  turnoverRanges
} from '~/constants/commercialProspecting';
import { DisplayAgencyDto } from '~/services/api.types';
import { eventTypes } from '~/constants/eventsLog';

export function isBoolean(val: any): val is boolean {
  return val === false || val === true;
}

export function isNumber(value: any): value is number {
  return typeof value === 'number' && isFinite(value);
}

export function convertIfNumber(value: any) {
  return isNumber(value) ? Number(value) : value;
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

// STRING
export const toNormalizeAndLower = (str: any) => {
  if (isString(str)) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      ?.toLowerCase();
  }
  return str;
};

export function capitalizeFirstLetter(string: string) {
  const lower = string.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
}
type DateInput = Date | string | number;
// DATE
export const dateWithoutTime = (date: DateInput) =>
  new Date(new Date(date).toDateString());

export const currentYearStart = new Date(
  `${new Date().getFullYear()}-01-01T00:00:00`
);
export const currentYearEnd = new Date(
  `${new Date().getFullYear()}-12-31T23:59:59`
);

export const currentMonthStart = new Date(startOfMonth(new Date()));
export const currentMonthEnd = new Date(endOfMonth(new Date()));

export const lastYearStart = new Date(
  `${new Date().getFullYear() - 1}-01-01T00:00:00`
);
export const lastYearEnd = new Date(
  `${new Date().getFullYear() - 1}-12-31T23:59:59`
);

export const lastMonthStart = new Date(subMonths(currentMonthStart, 1));
export const lastMonthEnd = new Date(subMonths(currentMonthEnd, 1));

export const getEndOfTheDayDate = (date: DateInput) => {
  const newEndDate = new Date(date);
  newEndDate.setHours(23, 59, 59);
  return newEndDate;
};

export const setHoursToDate = (date: DateInput, hour: number) => {
  const newEndDate = new Date(date);
  newEndDate.setHours(hour);
  return newEndDate;
};

export const getPreviousDayDate = (date: DateInput) => {
  const newEndDate = new Date(date);
  newEndDate.setDate(newEndDate.getDate() - 1);
  return newEndDate;
};

export const removeSecondsFromDate = (date: DateInput) => {
  const newEndDate = new Date(date);
  newEndDate.setSeconds(0);
  return newEndDate;
};

export const isValidDate = (date: any): date is Date => {
  return !isNaN(date) && date instanceof Date;
};

export const urlDateFormat = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

export function getTimeDifference(date: Date) {
  const difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30)
    return `${Math.floor(difference / 86400)} J`;
  else if (difference < 86400 * 30 * 12)
    return `${Math.floor(difference / 86400 / 30)} M`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} An`;
}

export const isNumericInput = (val: any) => {
  const rgx = /^[0-9]*\.?[0-9]*$/;
  return val.match(rgx);
};

export const roundNum = (num: number | string, precision = 2) => {
  // toFixed only, doesn't give a correct round // precision 1 or 2
  const roundUp = Number(num).toFixed(precision);
  return Number(roundUp);
};

export const numberToFixed = (
  number: number | string,
  fraction: number = 2
) => {
  const numberFormat = Number(number).toFixed(fraction);
  return numberFormat;
};

// toLocaleString not working with react-pdf (strange symbol appear)
export const numberWithSpacesAndxFractionDigits = (
  number: number | string,
  fraction: number = 2
) => {
  const numberFormat = numberToFixed(number, fraction);
  const parts = numberFormat.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const getNextDayDate = (date: Date) => {
  const newEndDate = new Date(date);
  newEndDate.setDate(newEndDate.getDate() + 1);
  return newEndDate;
};

export const isOdd = (number: number) => {
  return Boolean(number % 2);
};

export const randomColorFromPalette = (index: number = 0) => {
  const isIndexOdd = isOdd(index);
  const preselectedColorsBright = [
    '#e4d306',
    '#c6b800',
    '#a59b00',
    '#827c00',
    '#5e5b00',
    '#743abd',
    '#5a22a3',
    '#3a0085',
    '#4d44a1',
    '#5d5dca',
    '#00008a',
    '#7d3442', // brown palette
    '#60243a',
    '#8d7379'
  ];
  const preselectedColorsSoft = [
    '#d247e2',
    '#bc2ecd',
    '#a100b3',
    '#9d9edf',
    '#800093',
    '#9541a8',
    '#e196ff',
    '#e7148b',
    '#cb0074',
    '#aa0059',
    '#fda8cf'
  ];
  const preselectedColors = index
    ? isIndexOdd
      ? preselectedColorsSoft
      : preselectedColorsBright
    : [...preselectedColorsBright, ...preselectedColorsSoft];

  const randomColor =
    preselectedColors[Math.floor(Math.random() * preselectedColors.length)];
  return randomColor;
};

// to use it getNanoidNumber
export const getNanoidNumber = () => {
  const generateId = customAlphabet('1234567890', 16);
  return Number(generateId());
};

export enum FileType {
  IMG = 'IMG',
  DOC = 'DOC',
  PDF = 'PDF'
}

export const checkIfFileTypeAllowed = (
  file: any,
  type: FileType,
  fnMessage: (text: string) => void
) => {
  let allowedType: string[] = [];
  let allowedExtension: string[] = [];
  if (type === FileType.IMG) {
    allowedType = allowedImgType;
    allowedExtension = allowedImgExtension;
  }
  if (type === FileType.DOC) {
    allowedType = allowedDocType;
    allowedExtension = allowedDocExtension;
  }
  if (type === FileType.PDF) {
    allowedType = ['application/pdf'];
    allowedExtension = ['pdf'];
  }
  fnMessage('');
  const validTypesFormat = allowedType;
  const typeFormat = file.type;
  const validTypes = allowedExtension;
  const ext = file.name.split('.').pop();
  if (
    (ext && validTypes.indexOf(ext.toString().toLowerCase()) === -1) ||
    validTypesFormat.indexOf(typeFormat) === -1
  ) {
    fnMessage(
      `${strings.extension_fichier_non_autorisee_etc}: ${allowedExtension.join(
        ', '
      )}`
    );
    return false;
  }
  return true;
};

export const generateTemporaryInternalId = () => `${TEMPORARY_ID}${nanoid()}`;

export const detectInternalTemporaryId = (str: string | number | undefined) => {
  // we use internal id as key for data manipulation before submit, DB Id are number only
  // internal id are string starting by temporaryId- , example temporaryId-H1etGXR8_S5jd4i6B-myT
  return typeof str === 'string' && str.indexOf(TEMPORARY_ID) === 0;
};

export const detectRealDbId = (str: string | number | undefined) => {
  const isRealId =
    typeof str !== 'string' ||
    (typeof str === 'string' && str.indexOf(TEMPORARY_ID) !== 0);
  return isRealId;
};

export const eventBus = {
  on(event: any, callback: any) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event: any, data?: any) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: any, callback?: any) {
    document.removeEventListener(event, callback);
  }
};

export const smoothScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const smoothScrollToBottom = () => {
  window.scrollTo({
    top: document.body.scrollHeight || document.documentElement.scrollHeight,
    left: 0,
    behavior: 'smooth'
  });
};

export const getUserRoleTranslation = (
  userRole: Roles | string | null | undefined
) => {
  let userRoleFr;
  switch (userRole) {
    case Roles.Admin:
      userRoleFr = strings.Responsable_Grands_comptes;
      break;
    case Roles.Manager:
      userRoleFr = strings.Super_Assistant_e_commerciale;
      break;
    case Roles.Accountant:
      userRoleFr = strings.Comptable;
      break;
    case Roles.User:
      userRoleFr = strings.Assistant_e_commerciale_Grands_comptes;
      break;
    default:
      userRoleFr = '';
  }
  return userRoleFr;
};

export const geoDistanceCalc = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const toRadians = (degree: number): number => (Math.PI * degree) / 180;

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

export function getLocalISOString(date: Date) {
  const offset = date.getTimezoneOffset();
  const offsetAbs = Math.abs(offset);
  const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString();
  return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(
    Math.floor(offsetAbs / 60)
  ).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`;
}

export const searchFilter = (
  list: any[],
  search: string,
  elements: string[] // object key, where to search. e.g list = [{name: 'alex', age: 26}], elements= ['name', 'age']
) => {
  const filtered = list.filter((el) => {
    if (search?.length > 0) {
      return elements.some((item) => {
        return (
          el?.[item] &&
          toNormalizeAndLower(el?.[item])?.indexOf(
            toNormalizeAndLower(search)
          ) !== -1
        );
      });
    }
    return true;
  });
  return filtered;
};

type StringOrNot = string | null | undefined;

const countriesByCode = arrayToObject(countries, 'code');

export const getCountryWithDefault = (code: StringOrNot) =>
  code ? countriesByCode[code]?.name : defaultCountry;

export const getCountry = (code: StringOrNot) =>
  code ? countriesByCode[code]?.name : '';

const reminderTargetByValue = arrayToObject(reminderTarget, 'value');

export const getReminderTarget = (target: StringOrNot) =>
  target ? reminderTargetByValue?.[target]?.name : '';

const reminderMethodsByValue = arrayToObject(reminderMethods, 'value');

export const getReminderMethod = (method: StringOrNot) =>
  method ? reminderMethodsByValue?.[method]?.name : '';

const reminderTypesByValue = arrayToObject(reminderTypes, 'value');

export const getReminderType = (type: StringOrNot) =>
  type ? reminderTypesByValue?.[type]?.name : '';

const actionTypesByValue = arrayToObject(actionTypes, 'value');

export const getActionType = (actionType: StringOrNot) =>
  actionType ? actionTypesByValue?.[actionType]?.name : '';

const unitByValue = arrayToObject(quoteUnitTypes, 'value');

export const getUnit = (unit: StringOrNot) =>
  unit ? unitByValue?.[unit]?.name : '';

const quoteTypeByValue = arrayToObject(quoteTypes, 'value');

export const getQuoteType = (quoteType: StringOrNot) =>
  quoteType ? quoteTypeByValue?.[quoteType]?.name : '';

const salesEventByValue = arrayToObject(salesEventTypes, 'value');

export const getSaleEvent = (saleEvent: StringOrNot) =>
  saleEvent ? salesEventByValue?.[saleEvent]?.name : '';

const communicationChannelByValue = arrayToObject(
  communicationChannelTypes,
  'value'
);

export const getCommunicationChannel = (communicationChannel: StringOrNot) =>
  communicationChannel
    ? communicationChannelByValue?.[communicationChannel]?.name
    : '';

const companyStaffSizeRangesTitleByValue = arrayToObject(
  companyStaffSizeRanges,
  'value'
);

export const getCompanyStaffSizeRange = (
  staffSizeRangesTitle: number | StringOrNot
) =>
  staffSizeRangesTitle
    ? companyStaffSizeRangesTitleByValue?.[staffSizeRangesTitle]?.title
    : '';

const turnoverRangesTitleByValue = arrayToObject(turnoverRanges, 'value');

export const getTurnoverRangeRange = (
  turnoverRangeRange: number | StringOrNot
) =>
  turnoverRangeRange
    ? turnoverRangesTitleByValue?.[turnoverRangeRange]?.title
    : '';

const invoicePaymentAuthorizationTypesByValue = arrayToObject(
  invoicePaymentAuthorizationTypes,
  'value'
);

export const getPaymentAuthorizType = (authorizType: StringOrNot) =>
  authorizType
    ? invoicePaymentAuthorizationTypesByValue?.[authorizType]?.name
    : '';

export const getAgencyDenomination = (
  agencyList: DisplayAgencyDto[],
  agencyId: number | StringOrNot
) => {
  const agencyListById = arrayToObject(agencyList, 'id');
  return agencyId ? agencyListById?.[agencyId]?.denomination : '';
};

const eventTypesByValue = arrayToObject(eventTypes, 'value');

export const getEventLogType = (eventType: StringOrNot) =>
  eventType ? eventTypesByValue?.[eventType]?.name : '';
