import {
  INTERVENTIONS,
  ALL_INTERVENTIONS,
  HOME
} from '~/constants/internalLinks';
import { cleanObject } from '~/utils/objectUtils';

type BillingUrlPathProps = {
  customerId: string | null;
  buildingId: string | null;
  gcQuoteReference: string | null;
  gcInvoiceReference: string | null;
};

export const getBillingUrlPath = (props: BillingUrlPathProps) => {
  const { customerId, buildingId, gcQuoteReference, gcInvoiceReference } =
    props;
  let pathUrl = '';
  switch (true) {
    case Boolean(customerId && buildingId):
      pathUrl = `${INTERVENTIONS}?customerId=${customerId}&buildingId=${buildingId}`;
      break;
    case Boolean(gcQuoteReference):
      pathUrl = `${ALL_INTERVENTIONS}?filter=true&gcQuoteReference=${gcQuoteReference}`;
      break;
    case Boolean(gcInvoiceReference):
      pathUrl = `${ALL_INTERVENTIONS}?filter=true&gcInvoiceReference=${gcInvoiceReference}`;
      break;
  }
  return pathUrl;
};

type InterventionUrlPathProps = {
  customerId: string | null;
  buildingId: string | null;
  customerSearch: {
    customerName: string | null;
    contactName: string | null;
    contactPhone: string | null;
    customerAddress: string | null;
    customerCountry: string | null;
    customerSiret: string | null;
  };
};

export const getInterventionUrlPath = (props: InterventionUrlPathProps) => {
  const { customerId, buildingId, customerSearch } = props;
  let pathUrl = '';
  const cleanedCustomerSearch = cleanObject(customerSearch);
  // @ts-ignore
  const qsParams = new URLSearchParams(cleanedCustomerSearch).toString();

  switch (true) {
    case Boolean(buildingId):
      pathUrl = `${INTERVENTIONS}?customerId=${customerId}`;
      break;
    case Boolean(qsParams):
      pathUrl = `${HOME}?filter=true&${qsParams}`;
      break;
  }
  return pathUrl;
};
