/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from 'react';
import chevron_icon from '~/assets/icons/chevron-up-solid.svg';
import { smoothScrollToTop } from '~/utils/shared';
import './ScrollToTop.css';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasToShowHideBtn, setHasToShowHideBtn] = useState(false);

  useEffect(() => {
    const delay = 2000; // 2 seconde
    let timer: ReturnType<typeof setTimeout>;
    if (hasToShowHideBtn) {
      timer = setTimeout(() => setHasToShowHideBtn(false), delay);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasToShowHideBtn]);

  const toggleVisibleGoToTop = () => {
    if (!isVisible && window.scrollY > 1300) {
      setIsVisible(true);
    } else if (isVisible && window.scrollY <= 1300) {
      setIsVisible(false);
      setHasToShowHideBtn(false);
    }
  };

  const handleHoverOn = () => setHasToShowHideBtn(true);

  const hideGoToTopButton = () => {
    setIsVisible(false);
    setHasToShowHideBtn(false);
  };

  window.addEventListener('scroll', toggleVisibleGoToTop);

  return (
    <>
      {hasToShowHideBtn && (
        <div
          role="button"
          tabIndex={0}
          onKeyPress={hideGoToTopButton}
          onClick={hideGoToTopButton}
          className="hide-btn">
          x
        </div>
      )}
      <div
        role="button"
        tabIndex={0}
        onMouseOver={handleHoverOn}
        onKeyPress={smoothScrollToTop}
        onClick={smoothScrollToTop}
        className="scroll-top"
        style={{ display: isVisible ? 'inline' : 'none' }}>
        <img src={chevron_icon} alt="go to top" />
      </div>
    </>
  );
};

export default ScrollToTop;
