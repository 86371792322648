import {
  EventCommunicationChannel,
  SalesEventType
} from '~/services/api.types';

export const salesEventTypes = [
  {
    value: SalesEventType.DiscoveryAndProspection,
    name: 'Découverte et prospection'
  },
  {
    value: SalesEventType.PortfolioDevelopment,
    name: ' Développement du portefeuille'
  },
  { value: SalesEventType.Defence, name: 'Défense ' },
  {
    value: SalesEventType.CustomerSatisfaction,
    name: 'Satisfaction client'
  },
  { value: SalesEventType.SAV, name: 'SAV' },
  { value: SalesEventType.Technical, name: 'Technique' }
];

export const communicationChannelTypes = [
  {
    value: EventCommunicationChannel.Physical,
    name: 'Physique'
  },
  {
    value: EventCommunicationChannel.Online,
    name: 'En ligne'
  }
];

export const currentUserEventCalendarColor = 'green';
