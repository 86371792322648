import { SearchBoxData } from './interventions';
import { InterventionState } from '~/services/api.types';
import { ALL_INTERVENTIONS } from './internalLinks';

export const filters = {
  linkToInterventionsInProgress: `${ALL_INTERVENTIONS}?filter=true&status=${InterventionState.InProgress}`,
  linkToQuotesToSend: `${ALL_INTERVENTIONS}?filter=true&quoteAgcStatus=${SearchBoxData.NOT_SENT}`,
  linkToVisitReportsToSend: `${ALL_INTERVENTIONS}?filter=true&visitReportAgcStatus=${SearchBoxData.NOT_SENT}`,
  linkToInvoicesToSend: `${ALL_INTERVENTIONS}?filter=true&invoiceAgcStatus=${SearchBoxData.NOT_SENT}`
};
